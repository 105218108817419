import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useFacilityContractsQuery } from "../../../../../../store/apis/FacilityApis";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "../../../../../shared/EditButton";
import DeleteButton from "../../../../../shared/DeleteButton";
import dayjs from "dayjs";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import { BaseButton } from "components/shared/BaseButton";

export default function CommunityContracts() {
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const {
    data: contracts,
    isLoading,
    isError,
  } = useFacilityContractsQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: !id,
  });

  const communityColumns = [
    {
      field: "description",
      headerName: "Description",
      flex: 2,
      minWidth: 100,
      renderCell: (params) => {
        return (
          // Need to transfer this storage url to env file
          <Link
            target="_blank"
            // eslint-disable-next-line no-undef
            to={`${process.env.REACT_APP_STORAGE_URL}/${params.row.container}/${params.row.secureFileName}`}>
            {params.row.description}
          </Link>
        );
      },
    },
    {
      field: "documentType",
      minWidth: 50,
      flex: 1,
      headerName: "Type",
    },
    {
      field: "contractStartDate",
      minWidth: 50,
      flex: 1,
      headerName: "Start Date",
      valueGetter: (params) => dayjs(params?.value).toDate(),
      valueFormatter: (params) => {
        const isDateValid = dayjs(params?.value).isValid();
        return isDateValid
          ? dayjs(params?.value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },
    {
      field: "contractEndDate",
      minWidth: 50,
      flex: 1,
      headerName: "End Date",
      valueGetter: (params) => dayjs(params?.value).toDate(),
      valueFormatter: (params) => {
        const isDateValid = dayjs(params?.value).isValid();
        return isDateValid
          ? dayjs(params?.value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },
    {
      field: "dateUploaded",
      minWidth: 50,
      flex: 1,
      headerName: "Date Uploaded",
      valueGetter: (params) => dayjs(params?.value).toDate(),
      valueFormatter: (params) => {
        const isDateValid = dayjs(params?.value).isValid();
        return isDateValid
          ? dayjs(params?.value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },

    {
      field: "uploadedBy",
      minWidth: 50,
      flex: 1,
      headerName: "Uploaded By",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`addcommunitycontract?id=${id}&contractId=${params.row.id}`}
              data={contracts}
              title="Community Contract/Agreement"
            />
            <DeleteButton
              index={params.row.id}
              entityName={params.row?.description}
              apiPath="deleteFacilityContract"
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <BaseButton
        marginLeft={"0px !important"}
        sx={{ marginBottom: "8px", width: { xs: "100%", sm: "auto" } }}
        text={"Add Community Contract/Agreement"}
        endIcon={"post_add"}
        To={`addcommunitycontract?id=${id}`}
      />
      <BaseDataGrid
        rows={contracts}
        columns={communityColumns}
        error={isError}
        loading={isLoading}
        autoHeight={false}
        height={{ md: "37dvh", xl: "40dvh" }}
      />
    </>
  );
}
