import React from "react";

import ProtectedRoute from "../ProtectedRoute";
import { PAGE_ROLES } from "components/shared/constants";

// Consultant Modules Imports
import { Consultant } from "components/consultant/Consultant";

// Consultant/MyRoster
import { MyRosters } from "components/consultant/headerDetails/myRosters/MyRosters";
import NonPlannedVisit from "components/consultant/headerDetails/myRosters/nonPlannedVisit/NonPlannedVisit";
import { CommunityMenus } from "components/consultant/headerDetails/myRosters/communityMenu/CommunityMenu";
import { CommunityInformation } from "components/consultant/headerDetails/myRosters/communityMenu/communityInformation/CommunityInformation";
import { CommunityProfile } from "components/consultant/headerDetails/myRosters/communityMenu/communityProfile/CommunityProfile";
import { EnterpriseMenu } from "components/consultant/headerDetails/myRosters/enterpriseMenu/EnterpriseMenu";
import { EnterpriseInformation } from "components/consultant/headerDetails/myRosters/enterpriseMenu/enterpriseInformation/EnterpriseInformation";
import { EnterpriseProfile } from "components/consultant/headerDetails/myRosters/enterpriseMenu/enterpriseProfile/EnterpriseProfile";
import { ConsultantVisitDetails } from "components/consultant/headerDetails/myRosters/consultantVisitDetails/ConsultantVisitDetails";
import { VisitDetails } from "components/consultant/headerDetails/myRosters/consultantVisitDetails/visitDetails/VisitDetails";
// import { VisitReports } from "components/consultant/headerDetails/myRosters/consultantVisitDetails/visitReport/VisitReports";
import { ConsultantDetailsView } from "components/consultant/headerDetails/myRosters/consultantDetails/ConsultantDetailsView";
import { ConsultantDetails } from "components/consultant/headerDetails/myRosters/consultantDetails/consultantDetails/ConsultantDetails";

import AddNutritionalCare from "components/consultant/headerDetails/myRosters/consultantVisitDetails/visitReport/addNutritionalCare/AddNutritionalCare";
import NutritionalCares from "components/consultant/headerDetails/myRosters/consultantVisitDetails/visitReport/nutritionalCares/NutritionalCares";
import MemoReport from "components/consultant/headerDetails/myRosters/consultantVisitDetails/visitReport/memoReport/MemoReport";
import MealObservation from "components/consultant/headerDetails/myRosters/consultantVisitDetails/visitReport/mealObservation/MealObservation";
import AddResidentAssessment from "components/consultant/headerDetails/myRosters/consultantVisitDetails/visitReport/nutritionalCares/addResidentAssessment/AddResidentAssessment";
import { SendReport } from "components/consultant/headerDetails/myRosters/consultantVisitDetails/sendReport/SendReport";
import SubmitFinishedVisit from "components/consultant/headerDetails/myRosters/submitFinishedVisit/submitFinishedVisit";
import { SendReportToDiningRd } from "components/consultant/headerDetails/myRosters/consultantVisitDetails/sendReportToDining/SendReportToDiningRd";

// Consultant/Resources
import { Resources } from "components/consultant/headerDetails/resources/Resources";
import { DietManual } from "components/consultant/headerDetails/resources/dietManual/DietManual";
import { GuidelineManual } from "components/consultant/headerDetails/resources/guideManual/GuideManual";

const { CONSULTANT } = PAGE_ROLES;

const consultantRoutes = {
  path: "Consultant",
  element: (
    <ProtectedRoute allowedRoles={CONSULTANT}>
      <Consultant />
    </ProtectedRoute>
  ),
  children: [
    { path: "MyRosters", element: <MyRosters /> },
    { path: "MyRosters/submitVisit", element: <SubmitFinishedVisit /> },
    { path: "Resources", element: <Resources /> },
    { path: "Resources/DietManual", element: <DietManual /> },
    { path: "Resources/GuidelineManual", element: <GuidelineManual /> },
    { path: "MyRosters/CommunityProfile", element: <CommunityProfile /> },
  ],
};

const consultantMyRostersRoutes = {
  path: "Consultant/MyRosters",
  element: (
    <ProtectedRoute allowedRoles={CONSULTANT}>
      <ConsultantVisitDetails />
    </ProtectedRoute>
  ),
  children: [
    { path: "VisitDetails", element: <VisitDetails /> },
    // { path: "VisitReports", element: <VisitReports /> },
    { path: "VisitDetails/SendReport", element: <SendReport /> },
    {
      path: "VisitDetails/SubmitReportToHealthTech",
      element: <SendReportToDiningRd />,
    },
    {
      path: "VisitDetails/AddNutritionalCare",
      element: <AddNutritionalCare />,
    },
    { path: "VisitDetails/VisitReport", element: <MealObservation /> },
    { path: "VisitDetails/addMemo", element: <MemoReport /> },
    { path: "VisitDetails/NutritionalCares", element: <NutritionalCares /> },
    {
      path: "VisitDetails/AddResidentAssessment",
      element: <AddResidentAssessment />,
    },
    { path: "MyRosters/CommunityProfile", element: <CommunityProfile /> },
  ],
};

const consultantDetailsRoutes = {
  path: "Consultant/MyRosters",
  element: (
    <ProtectedRoute allowedRoles={CONSULTANT}>
      <ConsultantDetailsView />
    </ProtectedRoute>
  ),
  children: [
    { path: "ConsultantsDetails", element: <ConsultantDetails /> },
    { path: "MyRosters/CommunityProfile", element: <CommunityProfile /> },
  ],
};

const consultantCommunityRoutes = {
  path: "Consultant/Community",
  element: (
    <ProtectedRoute allowedRoles={CONSULTANT}>
      <CommunityMenus />
    </ProtectedRoute>
  ),
  children: [
    { path: "Profile", element: <CommunityProfile /> },
    { path: "Information", element: <CommunityInformation /> },
  ],
};

const consultantEnterpriseRoutes = {
  path: "Consultant/Enterprise",
  element: (
    <ProtectedRoute allowedRoles={CONSULTANT}>
      <EnterpriseMenu />
    </ProtectedRoute>
  ),
  children: [
    { path: "Visit", element: <EnterpriseProfile /> },
    { path: "Information", element: <EnterpriseInformation /> },
  ],
};

const consultantNonplannedvisitRoutes = {
  path: "Consultant/MyRosters/nonplannedvisit",
  element: <NonPlannedVisit />,
};

export const ConsultantRoute = {
  consultantRoutes,
  consultantMyRostersRoutes,
  consultantDetailsRoutes,
  consultantCommunityRoutes,
  consultantEnterpriseRoutes,
  consultantNonplannedvisitRoutes,
};
