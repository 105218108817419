import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import BaseSelect from "../../../../../../shared/baseForm/BaseSelect";
import BaseInput from "../../../../../../shared/baseForm/BaseInput";
import { StatesList } from "../../../../../../shared/states.constants";
import {
  useAddDietitianLicenseMutation,
  useUpdateDietitianLicenseMutation,
} from "../../../../../../../store/apis/DietitianAPis";
import BaseDataGrid from "../../../../../../shared/baseDataGrid/BaseDataGrid";
import DeleteButton from "../../../../../../shared/DeleteButton";
import BaseDatePicker from "components/shared/baseForm/BaseDatePicker";
import { REQUIRED_ERROR } from "utils/errorMessages";
import dayjs from "dayjs";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import size from "lodash/size";
import isEqual from "lodash/isEqual";

export default function LicenseSetup({
  isAuthenticated,
  currentDietitian,
  dietitianLicenses,
  loadingLicenses,
}) {
  const [edit, setEdit] = useState(false);
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: currentDietitian,
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const [
    addLicense,
    { isSubmitting: isSubmittingAdd, isSuccess: isSuccessAdd },
  ] = useAddDietitianLicenseMutation();

  const [
    updateLicense,
    { isSubmitting: isSubmittingUpdate, isSuccess: isSuccessUpdate },
  ] = useUpdateDietitianLicenseMutation();

  const isSubmitting = isSubmittingAdd || isSubmittingUpdate;
  const isSuccess = isSuccessAdd || isSuccessUpdate;

  function handleEdit(id, state, licenseNumber, expirationDate) {
    setEdit(true);
    reset({
      licenseId: id,
      state: state,
      licenseNumber: licenseNumber,
      expirationDate: expirationDate,
    });
  }

  const licensesColumn = [
    { field: "state", headerName: "State", flex: 0.5 },
    { field: "licenseNumber", headerName: "License Number", flex: 1 },
    {
      field: "expirationDate",
      valueFormatter: (params) => {
        const isDateValid = dayjs(params?.value).isValid();
        return isDateValid
          ? dayjs(params?.value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
      headerName: "Expiration Date",
      flex: 1,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      // minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Box display={"flex"} justifyContent={"center"}>
            <Button
              onClick={() =>
                handleEdit(
                  params.row.id,
                  params.row.state,
                  params.row.licenseNumber,
                  params.row.expirationDate
                )
              }>
              <span className="material-icons">edit</span>
            </Button>
            <DeleteButton
              index={params.row.id}
              entityName={"Dietitian License"}
              apiPath="deleteDietitianLicense"
            />
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    if (isSuccess && !isSubmitting) {
      reset({
        id: 0,
        dietitianUserId: currentDietitian?.id,
        state: "",
        licenseNumber: "",
        expirationDate: null,
      });
    }
  }, [isSuccessAdd, isSuccessUpdate]);

  const onSubmit = (data) => {
    data["dietitianUserId"] = currentDietitian?.id;
    if (isAuthenticated) {
      if (data?.licenseId) {
        updateLicense({
          ...data,
          id: data?.licenseId,
        });
        return;
      } else {
        addLicense({
          ...data,
        });
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <BaseSelect
          label="State"
          name="state"
          id="state"
          control={control}
          options={StatesList}
          validationProps={{
            required: REQUIRED_ERROR("State"),
          }}
          errors={errors}
        />
      </Grid>
      <Grid item xs={7}>
        <BaseInput
          label="License Number"
          name="licenseNumber"
          id="licenseNumber"
          control={control}
          errors={errors}
          validationProps={{
            required: REQUIRED_ERROR("License Number"),
            maxLength: {
              value: 20,
              message: "Value must be 20 digits or fewer",
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <BaseDatePicker
          label="Expiration Date"
          name="expirationDate"
          id="expirationDate"
          control={control}
          errors={errors}
          validationProps={{
            required: REQUIRED_ERROR("Expiration Date"),
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          color={"primary"}
          onClick={handleSubmit(onSubmit)}>
          {edit ? "Update" : "Add"}
        </Button>
      </Grid>

      {!isEqual(size(dietitianLicenses), 0) ? (
        <Grid item height={"30vh"} xs={12}>
          <BaseDataGrid
            // autoHeight={false}
            width={"99%"}
            columns={licensesColumn}
            rows={dietitianLicenses}
            loading={loadingLicenses}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}
