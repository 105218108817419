export const ResidentMenuElements = [
  {
    Name: "Details",
    Link: "/MealCard/resident/details",
    IsActive: false,
  },
  {
    Name: "Adaptive Equipment",
    Link: "/MealCard/resident/adaptiveEquipment",
    IsActive: false,
  },
  {
    Name: "Notes",
    Link: "/MealCard/resident/residentNotes",
    IsActive: false,
  },
  // {
  //   Name: "Beverages",
  //   Link: "/MealCard/resident/beverages",
  //   IsActive: false,
  // },
  {
    Name: "Meal Add-Ons",
    Link: "/MealCard/resident/mealExtras",
    IsActive: false,
  },
  {
    Name: "TableSide",
    Link: "/MealCard/resident/tablesideHistory",
    IsActive: false,
  },
  {
    Name: "Dislike/Allergy",
    Link: "/MealCard/resident/dislikes",
    IsActive: false,
  },
  {
    Name: "Substitutions",
    Link: "/MealCard/resident/substitutions",
    IsActive: false,
  },
  // {
  //   Name: "Allergy",
  //   Link: "/MealCard/resident/residentAllergy",
  //   IsActive: false,
  // },
  {
    Name: "Snack/Supplement",
    Link: "/MealCard/resident/residentSnacks",
    IsActive: false,
  },

  // {
  //   Name: "Liquid Consistency",
  //   Link: "/MealCard/resident/residentLiquid",
  //   IsActive: false,
  // },
  {
    Name: "Weight History",
    Link: "/MealCard/resident/weightHistory",
    IsActive: false,
  },
];
