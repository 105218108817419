/**
 * @see [Documentation](../../../Wiki/Documentations/FrontEnd/Constants/Roles.md)
 */
export const Roles = {
  Dietitian_Regional_Admin: "Dietitian_Regional_Admin",
  Dietitian_Global_Admin: "Dietitian_Global_Admin",
  Admin: "Admin",
  Dietitian_Hourly: "Dietitian_Hourly",
  Dietitian_1099: "Dietitian_1099",
  Dietitian_Salary: "Dietitian_Salary",
  Recipe_User: "Recipe_User",
  Menu_Team: "Menu_Team",
  Dining_Manager: "Dining_Manager",
  Kitchen_Staff: "Kitchen_Staff",
  Mealcard_User: "Mealcard_User",
  Tableside_User: "Tableside_User",
  Menu_User: "Menu_User",
  Recipe_Sharing: "Recipe_Sharing",
  Menu_Sharing: "Menu_Sharing",
  Advanced_User: "Advanced_User",
  Facility_Admin: "Facility_Admin",
  Menu_Signing: "Menu_Signing",
  AI_Chat: "AI_Chat",
  Corporate_Group_Portal: "Corporate_Portal",
  Facility_Portal: "Facility_Portal",
  Corporate_Group_Admin: "Corporate_Group_Admin",
};

/**
 * @see [Documentation](../../../Wiki/Documentations/FrontEnd/Constants/PAGEROLES.md)
 */
export const PAGE_ROLES = {
  ADMIN: [Roles.Admin],
  CONSULTANT: [
    // Roles.Admin, // removing so that you have to have explicit consultant role, not just admin
    Roles.Dietitian_Hourly,
    Roles.Dietitian_Salary,
    Roles.Dietitian_1099,
    Roles.Dietitian_Regional_Admin,
    Roles.Dietitian_Global_Admin,
  ],
  CONSULTANT_REPORTS: [
    Roles.Corporate_Group_Portal,
    Roles.Facility_Portal,
    // Roles.Admin, // removing so that you have to have explicit portal role, not just admin
  ],
  MEALCARD: [Roles.Admin, Roles.Menu_Team, Roles.Mealcard_User],
  PLATEFUL: [
    Roles.Admin,
    Roles.Recipe_User,
    Roles.Menu_Team,
    Roles.Dining_Manager,
    Roles.Kitchen_Staff,
  ],
  PLATEFUL_MENUS: [
    Roles.Admin,
    Roles.Menu_User,
    Roles.Menu_Team,
    Roles.Dining_Manager,
    Roles.Kitchen_Staff,
    Roles.Advanced_User,
  ],
  TABLESIDE: [Roles.Admin, Roles.Menu_Team, Roles.Tableside_User],
  AI_CHAT: [Roles.AI_Chat],
};

export const SCREEN_CONSTANTS = {
  Empty_String: "",
  Date_Format: "MM/DD/YYYY",
  DateTime_Format: "MM/DD/YYYY hh:mm A",
  Time_Format: "HH:mm:ss",
};

export const REPORT_TYPES = [
  {
    id: 1,
    // name: "Visit Summary",
    text: "Face Sheet",
    color: "success",
  },
  {
    id: 2,
    // name: "Meal Observation",
    text: "Meal Observation",
    color: "purple",
  },
  {
    id: 3,
    // name: "Kitchen Inspection",
    text: "Kitchen Inspection",
    color: "blue",
  },
  {
    id: 4,
    // name: "RD Assessment",
    text: "RD Assessment",
    color: "orange",
  },
  {
    id: 5,
    // name: "Dietitian Memo",
    text: "Dietitian Memo",
    color: "maroon",
  },
];
