import {
  Box,
  CircularProgress,
  Divider,
  Fade,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useAeCopyToCorporateGroupMutation,
  useFacilityAdaptiveEquipmentsQuery,
  useGetFacilityByIdQuery,
} from "../../../../../../store/apis/FacilityApis";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { BaseButton } from "components/shared/BaseButton";

export default function CommunityAdaptiveEqipments() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [openCopyModal, setOpenCopyModal] = useState(false);

  const { data: community, isSuccess: communitySuccess } =
    useGetFacilityByIdQuery(id, { skip: !id });

  const {
    data: facilityAEs,
    error: hasError,
    isLoading: fetching,
  } = useFacilityAdaptiveEquipmentsQuery(id);

  const facilityAdaptiveEquipmentColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
    },
  ];

  return (
    <>
      <Grid
        display={"flex"}
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent={"space-between"}
        marginBottom={"8px"}>
        <BaseButton
          sx={{ marginBottom: "10px", width: { xs: "100%", sm: "auto" } }}
          text={"Edit Community Adaptive Equipments"}
          To={`editCommunityAdaptiveEquipments?id=${id}`}
          endIcon={"edit_note"}
          marginLeft={"0px !important"}
        />

        <BaseButton
          sx={{ marginBottom: "10px", width: { xs: "100%", sm: "auto" } }}
          text={" Copy to Corporate Group"}
          endIcon={"copy_all"}
          onClick={() => setOpenCopyModal(true)}
          colors="error"
          marginLeft={"0px !important"}
        />
      </Grid>

      {openCopyModal && (
        <CopyToCorporateGroupModal
          open={openCopyModal}
          close={() => setOpenCopyModal(false)}
          cgName={communitySuccess && community?.corporateGroup}
          facilityName={communitySuccess && community?.name}
          id={id}
        />
      )}

      <BaseDataGrid
        rows={facilityAEs?.facilityAdaptiveEquipments}
        columns={facilityAdaptiveEquipmentColumns}
        error={hasError}
        loading={fetching}
        autoHeight={false}
        height={{ md: "37dvh", xl: "40dvh" }}
      />
    </>
  );
}

function CopyToCorporateGroupModal({ facilityName, cgName, id, open, close }) {
  const [startCopy, { isLoading, isSuccess }] =
    useAeCopyToCorporateGroupMutation();

  useEffect(() => {
    if (isSuccess) {
      close();
    }
  }, [isSuccess]);

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby={`copy-to-corporate-group-modal-title-${id}`}
      aria-describedby={`copy-to-corporate-group-modal-description-${id}`}>
      <Fade in={open}>
        <Box className={"deleteModal"}>
          <Box className="modalContent">
            <p>
              <span className="material-icons modalIcon" color={"warning"}>
                report
              </span>
            </p>
            <Box>
              <Typography
                id={`copy-to-corporate-group-modal-title-${id}`}
                variant="h6"
                component="h2"
                className="modalTitle">
                {`Adaptive Equipments for "${facilityName}"`}
              </Typography>
              <Typography
                id={`copy-to-corporate-group-modal-description-${id}`}
                sx={{ mt: 2 }}>
                {`This will remove the adaptive equipments for all communities that are linked to the "${cgName}" Corporate Group, and replace it with the adaptive equipments that are currently setup for this community. Are you sure you want to continue?`}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ my: 0.5 }} />
          <Box className="deleteActions" flex={true}>
            <BaseButton text="Cancel" onClick={close} colors="warning" />
            <BaseButton
              text="Continue"
              marginLeft="10px"
              startIcon={
                isLoading ? <CircularProgress color="white" size={18} /> : ""
              }
              colors="error"
              onClick={() => {
                startCopy(id);
              }}
            />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
