import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Modal,
  Fade,
  Divider,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { BaseButton } from "components/shared/BaseButton";
// Shared Components
import HeaderBox from "../../../../../shared/HeaderBox";
import GeneratedForm, {
  FORM_TYPES,
} from "../../../../../../shared/generatedForm/GeneratedForm";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";

// constants
import { AddMemo_ScreenNames, EmailField, RadioGroup } from "./form.constants";
import { SCREEN_CONSTANT } from "../../../constants";
import { useNavigate, useSearchParams } from "react-router-dom";
// APIs
import {
  useVisitReportsMutation,
  useGetDietitianMemoReportByIdQuery,
  useUpdateDietitianMemoReportMutation,
} from "../../../../../../../store/apis/ConsultantApis";
import dayjs from "dayjs";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import ValidationErrorSummary from "components/shared/validationErrorSummary/ValidationErrorSummary";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { theme } from "styles/theme";
import { BackButton } from "components/shared/BackButton";

function MemoReport() {
  // constants declartions
  const [searchParams] = useSearchParams();
  const visitPlanId = searchParams.get(SCREEN_CONSTANT.VISIT_PLAN_ID_QUERY);
  const visitId = searchParams.get("visitId");
  const id = searchParams.get("id");
  const visitReportTypeId = searchParams.get("visitReportTypeId");
  const currentDate = dayjs(new Date());
  const visitReportDescription = `Memo Form ${currentDate.format(
    SCREEN_CONSTANTS.Date_Format
  )}`;
  const [openDelModal, setOpenDelModal] = useState(false);
  const navigate = useNavigate();

  // useForm Hook
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  // isEdit able
  const isEdit = !!id;

  // useState
  const [count, setCount] = useState(1);
  const [deletedFields, setDeletedFields] = useState([]);
  const [actionType, setActionType] = useState({});
  const [fields, setFields] = useState([]);
  // APIs calls
  const [addMemoReport, { isLoading, isError, isSuccess }] =
    useVisitReportsMutation();
  const { data: reportData } = useGetDietitianMemoReportByIdQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const [
    updateMemoReport,
    {
      isLoading: updateLoading,
      isError: updateError,
      isSuccess: updateSuccess,
    },
  ] = useUpdateDietitianMemoReportMutation();

  useEffect(() => {
    if (isEdit && reportData) {
      const action = {};
      reportData?.itemList.forEach((item, index) => {
        action[`type_${index}`] = item?.actionType;
      });
      setActionType(action);
    }
  }, [isEdit, reportData]);

  const isLoadingTrue = isLoading || updateLoading;
  const isErrorTrue = isError || updateError;
  const isSuccessTrue = isSuccess || updateSuccess;

  useEffect(() => {
    if (isSuccessTrue && !isEdit) {
      reset();
      navigate(`/Consultant/MyRosters/VisitDetails?visitPlanId=${visitPlanId}`);
    }
  }, [isSuccessTrue, isErrorTrue]);

  // generateForm List

  useEffect(() => {
    if (actionType.type_0 && isEdit) {
      const generatedFormlist = [
        {
          id: 0,
          formFields: [
            {
              props: {
                name: `addMemo_0`,
                label: `Add Memo`,
                multiline: true,
                rows: 6,
              },
              validationProps: {
                required: REQUIRED_ERROR("Add Memo"),
              },

              size: 4,
            },
            {
              props: {
                name: `type_0`,
                defaultValue: actionType.type_0 || 1,
              },
              validationProps: {},
              options: RadioGroup,
              type: FORM_TYPES.Radio,
              size: 1,
            },
          ],
        },
      ];
      setFields(generatedFormlist);
    } else if (!isEdit) {
      const generatedFormlist = [
        {
          id: 0,
          formFields: [
            {
              props: {
                name: `addMemo_0`,
                label: `Add Memo`,
                multiline: true,
                rows: 6,
              },
              validationProps: {
                required: REQUIRED_ERROR("Add Memo"),
              },
              size: 4,
            },
            {
              props: {
                name: `type_0`,
                defaultValue: 1,
              },
              validationProps: {},
              options: RadioGroup,
              type: FORM_TYPES.Radio,
              size: 1,
            },
          ],
        },
      ];
      setFields(generatedFormlist);
    }
  }, [actionType, isEdit]);

  useEffect(() => {
    if (isEdit && reportData?.itemList && actionType && actionType?.type_0) {
      const resultObject = {};
      reportData?.itemList.forEach((item, index) => {
        resultObject[`addMemo_${index}`] = item?.memo;
        resultObject[`dietitianMemoId_${index}`] = item?.dietitianMemoItemId;
      });
      const newObject = {
        isConfidential: reportData?.isConfidential,
        memoTo: reportData?.memoTo,
        visitReportId: reportData?.visitReportId,
        dietitianMemoReportId: reportData?.dietitianMemoReportId,
        ...resultObject,
      };
      reset(newObject);

      // Initialize a new array to hold the new fields
      let newFields = [];

      for (let i = 1; i < reportData?.itemList.length; i++) {
        // Use the functional form of setCount
        setCount((prevCount) => {
          const newField = {
            id: prevCount,
            formFields: [
              {
                props: {
                  name: `addMemo_${prevCount}`,
                  label: `Add Memo`,
                  multiline: true,
                  rows: 6,
                },

                size: 4,
              },
              {
                props: {
                  name: `type_${prevCount}`,
                  defaultValue: actionType?.[`type_${prevCount}`] || 1,
                },
                validationProps: {},
                options: RadioGroup,
                type: FORM_TYPES.Radio,
                size: 1,
              },
            ],
          };
          newFields.push(newField);

          return prevCount + 1;
        });
      }
      setFields((prevFields) => [...prevFields, ...newFields]);
    }
  }, [reportData, actionType]);

  // Add more fields
  const handleAddMore = () => {
    setCount((prevCount) => prevCount + 1);

    const newField = {
      id: count,
      formFields: [
        {
          props: {
            name: `addMemo_${count}`,
            label: `Add Memo`,
            multiline: true,
            rows: 6,
          },

          size: 4,
        },
        {
          props: {
            name: `type_${count}`,
            defaultValue: actionType?.[`type_${count}`] || 1,
          },
          validationProps: {},
          options: RadioGroup,
          type: FORM_TYPES.Radio,
          size: 1,
        },
      ],
    };

    setFields((prevFields) => [...prevFields, newField]);
  };

  // handleDelete Function here
  const handleDelete = (idToDelete) => {
    setFields((prevFields) =>
      prevFields.filter((field) => {
        if (field.id === idToDelete) {
          setDeletedFields((prevDeletedFields) => [
            ...prevDeletedFields,
            field.id,
          ]);
        }
        return field.id !== idToDelete;
      })
    );
    setOpenDelModal(false);
  };

  // transform data
  const transformFormData = (rawData) => {
    const transformedData = {
      visitReportId: rawData?.visitReportId || 0,
      visitId: Number(visitId),
      visitReportDescription,
      visitReportTypeId: Number(visitReportTypeId),
      dietitianMemoReportId: rawData?.dietitianMemoReportId || 0,
      memoTo: rawData.memoTo,
      isConfidential: rawData.isConfidential,
      itemList: [],
    };

    for (const key in rawData) {
      if (key.startsWith("addMemo")) {
        const index = key.split("_")[1];
        const actionTypeKey = `type_${index}`;
        const actionTypeValue = rawData[actionTypeKey];
        const dietitianMemoIdKey = `dietitianMemoId_${index}`;
        const dietitianMemoIdValue = rawData[dietitianMemoIdKey];

        const memoItem = {
          dietitianMemoItemId: dietitianMemoIdValue || 0,
          memo: rawData[key],
          actionType: Number(actionTypeValue) || 0,
        };

        transformedData.itemList.push(memoItem);
      }
    }

    return transformedData;
  };

  // onSubmit Function
  function onSubmit(data) {
    const filteredData = {};

    for (const key in data) {
      const indexMatch = key.match(/\d+$/);
      const indexNumber = indexMatch ? Number(indexMatch[0]) : null;
      if (indexNumber && deletedFields.includes(indexNumber)) {
        continue;
      }
      filteredData[key] = data[key];
    }
    const transformedData = transformFormData(filteredData);

    if (filteredData && !isEdit) {
      addMemoReport(transformedData);
    }
    if (filteredData && isEdit) {
      updateMemoReport(transformedData);
    }
  }

  function CopyToCorporateGroupModal({ id, open, close }) {
    return (
      <Modal
        open={open}
        onClose={close}
        aria-labelledby={`copy-to-corporate-group-modal-title-${id}`}
        aria-describedby={`copy-to-corporate-group-modal-description-${id}`}>
        <Fade in={open}>
          <Box className={"deleteModal"}>
            <Box className="modalContent">
              <p>
                <span className="material-icons modalIcon" color={"warning"}>
                  report
                </span>
              </p>
              <Box>
                <Typography
                  id={`delete-modal-title-${id}`}
                  variant="h6"
                  component="h2"
                  className="modalTitle">
                  {`Are you sure you want to delete this item"?`}
                </Typography>
                <Typography
                  id={`delete-modal-description-${id}`}
                  sx={{ mt: 2 }}>
                  {`This item will be deleted immediately.`}
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ my: 0.5 }} />
            <Box className="deleteActions" flex={true}>
              <BaseButton text="Cancel" onClick={close} colors="warning" />
              <BaseButton
                text="Delete"
                marginLeft="10px"
                colors="error"
                onClick={() => {
                  handleDelete(id);
                }}
              />
            </Box>
          </Box>
        </Fade>
      </Modal>
    );
  }

  return (
    <Box padding={"10px"}>
      <BackButton iconDisabled={true} sx={{ marginBottom: "10px" }} />
      <HeaderBox
        visitPlanId={visitPlanId}
        ScreensArray={AddMemo_ScreenNames}
        observerTextCheck={{
          name: "Dietitian Memo",
          color: theme.palette.maroon.main,
        }}
      />
      <Box marginY={"10px"} display={"flex"} justifyContent={"right"}>
        <BaseSubmitButton
          sx={{
            position: "absolute",
            right: "0px",
          }}
          text="Save"
          onClick={handleSubmit(onSubmit)}
          isSubmitting={isLoadingTrue}
        />
      </Box>
      <Grid container spacing={1.5}>
        <GeneratedForm list={EmailField} control={control} errors={errors} />
      </Grid>
      <Box marginTop={"10px"}>
        <Typography fontWeight={"700"}>Intervention Type</Typography>

        {fields.map((field, index) => (
          <Box
            sx={{
              backgroundColor: "#ECECEC",
              borderRadius: 5,
              paddingBottom: 1,
            }}
            key={index}>
            <Grid container spacing={2} margin={1} md={11}>
              <GeneratedForm
                list={field?.formFields}
                control={control}
                errors={errors}
              />
              <Grid item sm={6} md={6} xl={6}>
                <Box
                  sx={{
                    float: "right",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "100%",
                  }}>
                  {field?.id !== 0 ? (
                    <>
                      {openDelModal && (
                        <CopyToCorporateGroupModal
                          open={openDelModal}
                          close={() => setOpenDelModal(false)}
                          id={field?.id}
                        />
                      )}
                      <Button
                        sx={{
                          backgroundColor: "#A80000",
                          borderRadius: 2,
                          width: "30px",
                          height: "30px",
                          minWidth: "30px",
                        }}
                        onClick={() => setOpenDelModal(true)}
                        // onClick={() => handleDelete(field?.id)}
                      >
                        <span
                          className="material-icons"
                          style={{
                            color: "#FFFFFF",
                            fontSize: "25px",
                            fontWeight: "bold",
                          }}>
                          delete
                        </span>
                      </Button>
                    </>
                  ) : null}
                  <Button
                    color={"white"}
                    variant="contained"
                    sx={{
                      marginY: "5px",
                      padding: "5px",
                      borderRadius: "10px",
                      borderColor: "#008385",
                      borderWidth: 4,
                      border: 1,
                      textTransform: "capitalize",
                      fontWeight: "800",
                    }}
                    onClick={handleAddMore}>
                    add
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>

      <ValidationErrorSummary errors={errors} />
    </Box>
  );
}

export default MemoReport;
