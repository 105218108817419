import { DMStyles } from "styles/Styles";
import { theme } from "styles/theme";

export const NUTRITIONAL_CARE_FIELDS = [
  {
    props: {
      name: "description",
      id: "description",
      label: "Description",
    },
    validationProps: {
      maxLength: {
        value: 200,
        message: "Description should not be greater than 200 characters.",
      },
    },
    size: 6,
  },
];

export const NUTRITIONAL_CARE_DEFAULTS = {
  visitReportTypeId: 4,
  visitId: "",
  description: "",
};

export const Add_Nutrition_ScreenNames = [
  {
    id: 1,
    screenName: false,
    bgColor: theme.palette.primary.main,
    fontWeight: "600",
    color: DMStyles.White,
    marginBottom: "5px",
  },
  {
    id: 2,
    screenName: "Nutrition Care",
    bgColor: theme.palette.orange.main,
    fontWeight: "500",
    color: DMStyles.White,
    marginBottom: "0px",
  },
];
