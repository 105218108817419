import React, { useState, useEffect, useCallback } from "react";
import { Typography, Button, Grid, CircularProgress, Box } from "@mui/material";
import { unstable_useBlocker as useBlocker } from "react-router-dom";
import {
  useGetHighlightQuery,
  useAddHighlightMutation,
} from "../../../store/apis/PrintReportApis";
import { SketchPicker } from "react-color";
import "./Highlight.css";
import { EditSkeleton } from "../../admin/admin.overlays";
import BlockerModal from "components/shared/blockerModal/BlockerModal";

export default function Highlight() {
  const { data: hightlights, isFetching: loading } = useGetHighlightQuery();

  // unsaved changes functionality here
  const [blockerModal, setBlockerModal] = useState(false);
  const [hasChange, setHasChange] = useState(false);

  // Block navigating elsewhere when data has been entered into the input
  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) =>
      hasChange && currentLocation.pathname !== nextLocation.pathname,
    [hasChange]
  );

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === "blocked") {
      setBlockerModal(true);
    }
  }, [blocker]);

  // Page Reload and navigation alert implementations
  useEffect(() => {
    window.onbeforeunload = function () {
      if (hasChange) {
        return "You have unsaved changes. Are you sure you want to leave?";
      }
    };
  }, [hasChange]);

  const handleOnLeave = () => {
    blocker.proceed();
    setBlockerModal(false);
    setHasChange(false);
  };
  const handleOnStay = () => {
    blocker.reset();
    setBlockerModal(false);
  };

  const [addhighlight, { isError: hasError, isLoading: saveLoading }] =
    useAddHighlightMutation();

  const onSubmit = () => {
    setHasChange(false);
    addhighlight({
      printOptions: {
        id: hightlights?.printOptions?.id,
        facilityId: hightlights?.printOptions?.facilityId,
        mealcardNotesColor,
        mealcardDislikeColor,
        mealcardAllergyColor,
        mealcardDietColor,
        mealcardDietTextureColor,
        mealcardDietOtherColor,
        mealcardThickenedLiquidColor,
        mealcardRestrictionQuantity,
        mealcardAdaptiveEquipmentColor,
        mealcardMealAddOnColor,
      },
      listFacilityDiet: listFacilityDiet,
    });
    if (!hasError) {
      // navigate(-1);
    }
  };

  const whiteColor = "#ffffff";
  const mealCardColors = hightlights?.printOptions;
  const [iscolorUpdate, setIscolorUpdatev] = useState(false);
  const [listFacilityDiet, setlistFacilityDiet] = useState();
  const [colorShow, setColorShow] = useState(false);
  const [mealcardNotesColor, setmealcardNotesColor] = useState(whiteColor);
  const [mealcardDislikeColor, setmealcardDislikeColor] = useState(whiteColor);
  const [mealcardAllergyColor, setmealcardAllergyColor] = useState(whiteColor);
  const [mealcardDietColor, setmealcardDietColor] = useState(whiteColor);
  const [mealcardDietTextureColor, setmealcardDietTextureColor] =
    useState(whiteColor);
  const [mealcardDietOtherColor, setmealcardDietOtherColor] =
    useState(whiteColor);
  const [mealcardThickenedLiquidColor, setmealcardThickenedLiquidColor] =
    useState(whiteColor);
  const [mealcardRestrictionQuantity, setmealcardRestrictionQuantity] =
    useState(whiteColor);
  const [mealcardAdaptiveEquipmentColor, setmealcardAdaptiveEquipmentColor] =
    useState(whiteColor);
  const [mealcardMealAddOnColor, setmealcardMealAddOnColor] =
    useState(whiteColor);

  useEffect(() => {
    const temp = [];
    hightlights?.listFacilityDiet?.length > 0 &&
      hightlights?.listFacilityDiet?.map((item) => {
        temp?.push({
          id: item.id,
          dietId: item.dietId,
          facilityId: item.facilityId,
          dietName: item.dietName,
          lastUpdatedBy: item.lastUpdatedBy,
          lastUpdated: item.lastUpdated,
          sortOrder: item.sortOrder,
          dietColor: item.dietColor,
          commonDietName: item.commonDietName,
        });
      });

    setlistFacilityDiet(temp);
    setmealcardNotesColor(mealCardColors?.mealcardNotesColor);
    setmealcardDislikeColor(mealCardColors?.mealcardDislikeColor);
    setmealcardAllergyColor(mealCardColors?.mealcardAllergyColor);
    setmealcardDietColor(mealCardColors?.mealcardDietColor);
    setmealcardDietTextureColor(mealCardColors?.mealcardDietTextureColor);
    setmealcardDietOtherColor(mealCardColors?.mealcardDietOtherColor);
    setmealcardThickenedLiquidColor(
      mealCardColors?.mealcardThickenedLiquidColor
    );
    setmealcardRestrictionQuantity(mealCardColors?.mealcardRestrictionQuantity);
    setmealcardAdaptiveEquipmentColor(
      mealCardColors?.mealcardAdaptiveEquipmentColor
    );
    setmealcardMealAddOnColor(mealCardColors?.mealcardMealAddOnColor);
  }, [hightlights]);

  function ColorBoxUI(colorText, color, setColor, type) {
    return (
      <div>
        <div className="color-picker">
          <div className="color-picker-box">
            <div
              type="button"
              className="color-box"
              style={{ background: color ? color : "black" }}
              onClick={() => {
                setColorShow(colorText);
              }}
            />

            <p className="color-text">{colorText}</p>
          </div>
        </div>
        {colorShow == colorText && (
          <div className="popover">
            <div className="cover">
              <span
                onClick={() => {
                  setColorShow("");
                }}
                className="material-icons close">
                close
              </span>
              <SketchPicker
                onChangeComplete={(color) => {
                  if (type == "dietArray") {
                    listFacilityDiet[setColor].dietColor = color.hex;
                    setIscolorUpdatev(!iscolorUpdate);
                    setHasChange(true);
                  } else {
                    setColor(color.hex);
                    setHasChange(true);
                  }
                }}
                color={color ? color : "black"}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
  return (
    <>
      {blockerModal ? (
        <BlockerModal
          text={`Hold up! You've got unsaved changes. Are you sure you want to leave?`}
          open={blockerModal}
          onStay={handleOnStay}
          onLeave={handleOnLeave}
        />
      ) : null}
      <Box display="flex" justifyContent="space-between" margin={"5px"}>
        <Typography variant="h6" component="h2">
          {`Highlights Setup`}
        </Typography>
        <Button
          color={"primary"}
          className="baseButton"
          variant="contained"
          onClick={() => onSubmit()}
          startIcon={
            saveLoading ? <CircularProgress color="white" size={18} /> : ""
          }>
          {"Save"}
        </Button>
      </Box>
      {loading ? (
        <EditSkeleton />
      ) : (
        <Box
          overflow={"auto"}
          height={{ md: "70vh", xl: "75vh" }}
          margin={"5px"}>
          <Grid container spacing={2} marginBottom={"5px"}>
            <Grid item xs={6}>
              <Typography variant="h5" component={"h5"} fontWeight={600}>
                MealCard Highlights
              </Typography>
              {ColorBoxUI(
                "Notes Color",
                mealcardNotesColor,
                setmealcardNotesColor
              )}
              {ColorBoxUI(
                "Dislike Color",
                mealcardDislikeColor,
                setmealcardDislikeColor
              )}
              {ColorBoxUI(
                "Allergy Color",
                mealcardAllergyColor,
                setmealcardAllergyColor
              )}
              {ColorBoxUI(
                "Diet Color",
                mealcardDietColor,
                setmealcardDietColor
              )}
              {ColorBoxUI(
                "Texture Color",
                mealcardDietTextureColor,
                setmealcardDietTextureColor
              )}
              {ColorBoxUI(
                "Diet Other Color",
                mealcardDietOtherColor,
                setmealcardDietOtherColor
              )}
              {ColorBoxUI(
                "Liquid Consistency Color",
                mealcardThickenedLiquidColor,
                setmealcardThickenedLiquidColor
              )}
              {ColorBoxUI(
                "Restriction Color",
                mealcardRestrictionQuantity,
                setmealcardRestrictionQuantity
              )}
              {ColorBoxUI(
                "Adaptive Equipment Color",
                mealcardAdaptiveEquipmentColor,
                setmealcardAdaptiveEquipmentColor
              )}
              {ColorBoxUI(
                "Meal Add-Ons Color",
                mealcardMealAddOnColor,
                setmealcardMealAddOnColor
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" component={"h5"} fontWeight={600}>
                Diet Highlights
              </Typography>
              {listFacilityDiet?.map((item, index) => {
                return ColorBoxUI(
                  item?.dietName,
                  item.dietColor,
                  index,
                  "dietArray"
                );
              })}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
