// react imports
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
// custom css
import "../../../myRoster.css";
// MUI components
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Button,
  Grid,
  CircularProgress,
  Tooltip,
} from "@mui/material";
// MUI Icons
import ExpandMore from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
// Shared Components
import GeneratedForm from "../../../../../../shared/generatedForm/GeneratedForm";
import HeaderBox from "../../../../../shared/HeaderBox";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import BaseCheckBox from "../../../../../../shared/baseForm/BaseCheckBox";
import BaseInput from "../../../../../../shared/baseForm/BaseInput";
// form constants
import {
  KitchenFields,
  MealFields,
  observerFieldText,
  consultSummaryFields,
  MealObservation_ScreenNames,
} from "./form.constants";
import { SCREEN_CONSTANT } from "../../../constants";
// api imports
import {
  useAddVisitReportMutation,
  useGetVisitReportsByIdQuery,
  useGetVisitReportsTaskQuery,
  useUpdateVisitReportMutation,
} from "../../../../../../../store/apis/ConsultantApis";
import dayjs from "dayjs";
import ValidationErrorSummary from "components/shared/validationErrorSummary/ValidationErrorSummary";
import size from "lodash/size";
import { BackButton } from "components/shared/BackButton";

// Component starts from here
function MealObservation() {
  // File state
  const [file, setFile] = useState([]);
  // const [fileName, setFileName] = useState();
  // const [filePreview, setFilePreview] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [isErrors, setIsErrors] = useState(false);

  // Search Params from React Router dom
  const [searchParams] = useSearchParams();
  const visitPlanId = searchParams.get(SCREEN_CONSTANT.VISIT_PLAN_ID_QUERY);
  const visitId = searchParams.get("visitId");
  const visitReportTypeId = searchParams.get("visitReportTypeId");
  const id = searchParams.get("Id");
  const isEdit = !!id;
  const navigate = useNavigate();

  // ObserverText Conditions
  const observerTextCheck =
    visitReportTypeId == 2
      ? observerFieldText.mealObservation
      : visitReportTypeId == 3
      ? observerFieldText.kitchenObservation
      : visitReportTypeId == 1
      ? observerFieldText.consultSummary
      : "";

  // GeneratedForm Conditions
  const generatedFormCheck =
    visitReportTypeId == 2
      ? MealFields
      : visitReportTypeId == 3
      ? KitchenFields
      : visitReportTypeId == 1
      ? consultSummaryFields
      : null;

  // API Call here
  const { data } = useGetVisitReportsTaskQuery(visitReportTypeId, {
    refetchOnMountOrArgChange: true,
  });

  const { data: reportData } = useGetVisitReportsByIdQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const [
    addVisitReport,
    { isLoading: addLoading, isError: addError, isSuccess: addSuccess },
  ] = useAddVisitReportMutation();

  const [
    updateVisitReport,
    { error: updateError, isLoading: updateLoading, isSuccess: updateSuccess },
  ] = useUpdateVisitReportMutation();

  const isLoading = addLoading || updateLoading;
  const isSuccess = addSuccess || updateSuccess;
  const isError = addError || updateError;

  useEffect(() => {
    if (isEdit && reportData) {
      const isDate =
        dayjs(reportData?.description, "h:mm A").format("HH:mm") !==
        "Invalid Date";
      setFile(reportData?.attachments);
      setAttachments(reportData?.attachments);
      const resultObject = {};
      reportData?.configs?.forEach((obj) => {
        resultObject[`hasSeeRecommendation-${obj.taskId}`] =
          obj.seeRecommendation;
        resultObject[`hasComment-${obj.taskId}`] = obj.comment;
        resultObject[`hasMeetsStandard-${obj.taskId}`] = obj.meetsStandard;
        resultObject[`hasNeedsCorrection-${obj.taskId}`] = obj.needsCorrection;
        resultObject[`hasCompleteNext-${obj.taskId}`] = obj.completeNextConsult;
        resultObject[`hasCompleted-${obj.taskId}`] = obj.completed;
        resultObject[`id-${obj.taskId}`] = obj.id;
      });

      const newObject = {
        reportDescription: isDate
          ? dayjs(reportData?.description, "hh:mm A").format("HH:mm")
          : reportData?.description,
        id: id,
        visitId: visitId,
        visitReportType: reportData?.visitReportType,
        confidential: reportData?.includeSignatureLine,
        ...resultObject,
      };
      reset(newObject);
    }
  }, [id, reportData]);

  useEffect(() => {
    if (isSuccess) {
      reset();
      navigate(-1);
    }
  }, [isSuccess, navigate, isError]);

  // Task IDs in an array from data
  const taskIds = data
    ? data.flatMap((sect) =>
        sect.items
          ? sect.items.flatMap((item) =>
              item.configs
                ? item.configs.map((config) => config?.taskId).filter(Boolean)
                : []
            )
          : []
      )
    : [];

  // useForm Hooks destructuring
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    method: "all",
    shouldUnregister: false,
  });

  // Transform Data logic here
  function transformData(rawData) {
    const description =
      typeof rawData?.reportDescription === "object"
        ? dayjs(rawData.reportDescription).format("hh:mm A")
        : rawData.reportDescription;
    const payload = {
      id: id || 0,
      visitReportTypeId,
      visitReportType: rawData?.visitReportType ?? "string",
      visitId,
      description: description,
      includeSignatureLine: rawData?.confidential || true,
      reportDescription: description,
      attachmentUrl: rawData?.attachmentUrl || "string",
      attachments: attachments ?? [],
      configs: [],
      files: [],
    };

    // pushing objects into configs array by comparing with taskIds
    taskIds?.forEach((taskId) => {
      const taskIdString = taskId.toString();
      const matchingKey = Object.keys(rawData).find((key) =>
        key.endsWith(`-${taskIdString}`)
      );
      if (matchingKey) {
        const config = {
          taskId,
          id: rawData[`id-${taskIdString}`] || 0,
          comment: rawData[`hasComment-${taskIdString}`] ?? " ",
          completeNextConsult:
            rawData[`hasCompleteNext-${taskIdString}`] ?? false,
          completed: rawData[`hasCompleted-${taskIdString}`] ?? false,
          meetsStandard: rawData[`hasMeetsStandard-${taskIdString}`] ?? false,
          needsCorrection:
            rawData[`hasNeedsCorrection-${taskIdString}`] ?? false,
          seeRecommendation:
            rawData[`hasSeeRecommendation-${taskIdString}`] ?? false,
        };

        payload.configs.push(config);
      }
    });
    return payload;
  }

  if (!data) {
    return (
      <Box className="loader">
        <CircularProgress />
      </Box>
    );
  }

  const handleFileUpload = (e) => {
    if (e.target.files) {
      const files = e.target.files;
      const maxLimitFiles = size(files) + size(file);
      maxLimitFiles > 10 ? setIsErrors(true) : setIsErrors(false);
      maxLimitFiles <= 10
        ? setFile((prev) => (prev ? [...prev, ...files] : [...files]))
        : null;
    }
  };
  const handleUndoFileSelection = (items) => {
    const deleteFile = file.filter((item) =>
      items.name ? item.name != items.name : item.id !== items.id
    );
    setFile(deleteFile);

    items?.attachmentUrl
      ? setAttachments((prevAttachments) =>
          prevAttachments.map((attachment) =>
            attachment.id === items.id
              ? { ...attachment, isToDelete: true }
              : attachment
          )
        )
      : null;
  };

  // On Submit function
  const onSubmit = (data) => {
    if (data) {
      const transformedData = transformData(data);

      // Create a new FormData object
      let dataToSend = new FormData();

      Object.entries(transformedData).forEach(([property, value]) => {
        if (property === "files") {
          file?.forEach((file) => {
            file.name ? dataToSend.append("files", file) : null;
          });
        } else if (Array.isArray(value)) {
          // Handle arrays (e.g., configs array)
          value.forEach((item, index) => {
            Object.entries(item).forEach(([subKey, subValue]) => {
              dataToSend.append(`${property}[${index}][${subKey}]`, subValue);
            });
          });
        } else {
          dataToSend.append(property, value);
        }
      });

      // Attach the file to the FormData object
      dataToSend.append("files", file);

      if (data && !isEdit) {
        addVisitReport(dataToSend);
      } else if (data && isEdit) {
        updateVisitReport(dataToSend);
      }
    }
  };

  return (
    <>
      <Box padding="5px">
        <BackButton iconDisabled={true} sx={{ marginBottom: "10px" }} />
        <HeaderBox
          visitPlanId={visitPlanId}
          ScreensArray={MealObservation_ScreenNames}
          observerTextCheck={observerTextCheck}
        />

        <Box maxWidth="20vw" marginBottom="8px">
          <GeneratedForm
            list={generatedFormCheck}
            control={control}
            errors={errors}
          />
        </Box>
        {data?.map((section, index) => (
          <Box
            key={index}
            className={visitReportTypeId == 1 ? "consultDataBox" : "dataBox"}>
            <Typography
              marginY="10px"
              paddingX="5px"
              component="h2"
              fontWeight="900"
              color="primary">
              {section.section}
            </Typography>
            {section?.items?.map((item, index) => (
              <Box key={index}>
                <Box className="visitReportsAccordian">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <Typography component="h3" fontWeight="900">
                        {item?.name}
                      </Typography>
                    </AccordionSummary>

                    <Divider />
                    <AccordionDetails>
                      <Box>
                        <Box>
                          {item?.configs?.map((config, taskId) => (
                            <Box key={taskId} className="configBox">
                              <Box width={{ md: "20vw", xl: "25vw" }}>
                                <Typography
                                  fontWeight="600"
                                  component="p"
                                  fontSize="13px">
                                  {config?.name}
                                </Typography>
                              </Box>
                              <Box width="95%" marginTop="5px">
                                <Grid
                                  container
                                  className="gridStyling"
                                  spacing={0.5}>
                                  {config?.hasCompleted ? (
                                    <Grid item>
                                      <BaseCheckBox
                                        name={`hasCompleted-${config?.taskId}`}
                                        id={`hasCompleted-${config?.taskId}`}
                                        label="Completed"
                                        control={control}
                                        errors={errors}
                                        size="small"
                                      />
                                    </Grid>
                                  ) : null}
                                  {config?.hasMeetsStandard ? (
                                    <Grid item>
                                      <BaseCheckBox
                                        name={`hasMeetsStandard-${config?.taskId}`}
                                        id={`hasMeetsStandard-${config?.taskId}`}
                                        label="Meets Standard"
                                        control={control}
                                        errors={errors}
                                        size="small"
                                      />
                                    </Grid>
                                  ) : null}
                                  {/*{!config.hasCompleted ? (*/}
                                  {/*  <Grid item>*/}
                                  {/*    <BaseCheckBox*/}
                                  {/*      name={`hasCompleted-${config?.taskId}`}*/}
                                  {/*      id={`hasCompleted-${config?.taskId}`}*/}
                                  {/*      label="In Progress"*/}
                                  {/*      control={control}*/}
                                  {/*      errors={errors}*/}
                                  {/*      size="small"*/}
                                  {/*    />*/}
                                  {/*  </Grid>*/}
                                  {/*) : null}*/}
                                  {config?.hasCompleteNext ? (
                                    <Grid item>
                                      <BaseCheckBox
                                        name={`hasCompleteNext-${config?.taskId}`}
                                        id={`hasCompleteNext-${config?.taskId}`}
                                        label="Future Consult"
                                        control={control}
                                        errors={errors}
                                        size="small"
                                      />
                                    </Grid>
                                  ) : null}
                                  {config?.hasNeedsCorrection ? (
                                    <Grid item>
                                      <BaseCheckBox
                                        name={`hasNeedsCorrection-${config?.taskId}`}
                                        id={`hasNeedsCorrection-${config?.taskId}`}
                                        label="Action Required"
                                        control={control}
                                        errors={errors}
                                        size="small"
                                      />
                                    </Grid>
                                  ) : null}
                                  {config?.hasSeeRecommendation ? (
                                    <Grid item>
                                      <BaseCheckBox
                                        name={`hasSeeRecommendation-${config?.taskId}`}
                                        id={`hasSeeRecommendation-${config?.taskId}`}
                                        label="See Recommend"
                                        control={control}
                                        errors={errors}
                                        size="small"
                                      />
                                    </Grid>
                                  ) : null}
                                  {config?.hasComment ||
                                  watch(
                                    `hasSeeRecommendation-${config.taskId}`
                                  ) ? (
                                    <Grid item md={5}>
                                      <BaseInput
                                        name={`hasComment-${config?.taskId}`}
                                        id={`hasComment-${config?.taskId}`}
                                        label="Comment here"
                                        control={control}
                                        errors={errors}
                                        type={"text"}
                                        size="small"
                                      />
                                    </Grid>
                                  ) : null}
                                </Grid>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
            ))}
          </Box>
        ))}

        {isErrors ? (
          <Typography sx={{ color: "red", marginTop: "10px" }}>
            {" "}
            You can attach a maximum of 10 files{" "}
          </Typography>
        ) : null}
        <Box
          marginTop="10px"
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}>
          <Tooltip
            title={
              size(file) >= 10 ? "You can attach a maximum of 10 files" : null
            }
            placement="top">
            <>
              <Button
                component="label"
                variant="outlined"
                startIcon={<span className="material-icons">attachment</span>}
                sx={{ marginRight: "1rem", textTransform: "none" }}
                disabled={size(file) >= 10 ? true : false}>
                {`Select File`}
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .pdf, .txt, .docx, .xls,"
                  hidden
                  multiple
                  onChange={handleFileUpload}
                />
              </Button>
            </>
          </Tooltip>

          <BaseSubmitButton
            onClick={handleSubmit(onSubmit)}
            isSubmitting={isLoading}
            text="Save"
          />
        </Box>

        {file?.map((file, index) => (
          <Box
            key={index}
            marginTop="10px"
            display={"flex"}
            alignItems={"center"}>
            <Button
              component="label"
              variant="outlined"
              startIcon={<span className="material-icons">attachment</span>}
              sx={{ marginRight: "1rem", textTransform: "none" }}>
              {file.name ? file.name : file.attachmentUrl.split("/").pop()}
            </Button>
            <span style={{ cursor: "pointer" }}>
              {file && (
                <ClearIcon
                  variant="outlined"
                  color="error"
                  onClick={() => handleUndoFileSelection(file)}
                />
              )}
            </span>
          </Box>
        ))}
        <ValidationErrorSummary errors={errors} />
      </Box>
    </>
  );
}

export default MealObservation;
