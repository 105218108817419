import React, { useState, useEffect } from "react";
import { Typography, Grid } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import {
  useGetDiningAreaByIdQuery,
  useGetDiningAreaTableListQuery,
} from "../../../store/apis/ResidentApis";
import { EditButton } from "../../shared/EditButton";
import DeleteButton from "../../shared/DeleteButton";
import BaseDataGrid from "../../shared/baseDataGrid/BaseDataGrid";
import { BaseButton } from "components/shared/BaseButton";

export default function DiningAreaTables() {
  const [DiningAreaName, setDiningAreaName] = useState();

  const [searchParams] = useSearchParams();

  const diningAreaId = searchParams.get("diningAreaId");

  const {
    data: diningArea,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetDiningAreaByIdQuery(diningAreaId);

  useEffect(() => {
    if (currentSuccess && !currentFetching && diningArea) {
      setDiningAreaName(diningArea.name);
    }
  }, [diningArea]);

  const {
    data: diningAreaList,
    isFetching: loading,
    isError: hasError,
  } = useGetDiningAreaTableListQuery(
    {
      diningAreaId: diningAreaId,
    },
    { refetchOnMountOrArgChange: true, skip: !diningAreaId }
  );

  const diningAreasColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`/MealCard/settings/DiningAreaTables/AddDiningAreaTables?diningAreaId=${diningAreaId}&id=${params.row.id}`}
              data={diningAreaList}
              title="dining area Table"
            />

            <DeleteButton
              index={params.row.id}
              entityName={params.row["name"] || ""}
              apiPath="deleteDiningAreaTable"
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Grid container spacing={1} paddingRight={1} marginTop={1}>
        <Grid item sm={8} md={10} lg={10} xl={10}>
          <Typography variant="h6" component="h2">
            {`Tables for ${DiningAreaName}`}
          </Typography>
        </Grid>
        <Grid item sm={4} md={2} lg={2} xl={2}>
          <BaseButton
            text="Add Table"
            endIcon={<span className="material-icons">post_add</span>}
            To={`/MealCard/settings/DiningAreaTables/AddDiningAreaTables?diningAreaId=${diningAreaId}`}
          />
        </Grid>
        <Grid item sm={12} md={12} lg={12} xl={12}>
          <BaseDataGrid
            rows={diningAreaList || []}
            loading={loading}
            columns={diningAreasColumns}
            error={hasError}
            autoHeight={false}
            height={{
              xs: "50vh",
              sm: "65vh",
              md: "58vh",
              lg: "63vh",
              xl: "67vh",
            }}
            sx={{
              marginY: "0px !important",
              paddingY: "0px !important",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
