import React, { useEffect, useState } from "react";
import {
  useGetPackingSlipListQuery,
  usePrintPackingSlipMutation,
} from "../../../../../../store/apis/PackingSlipAPIs";
import { Link, useSearchParams } from "react-router-dom";
import {
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { DownloadLinkHelper } from "../../../../../shared/utils/helpers";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "../../../../../shared/EditButton";
import DeleteButton from "../../../../../shared/DeleteButton";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import dayjs from "dayjs";
import { isNull } from "lodash";
import { BaseButton } from "components/shared/BaseButton";

export default function CommunityHTIOrders() {
  const [searchParams] = useSearchParams();

  const facilityId = searchParams.get("id");
  const {
    data: htiOrders,
    isLoading,
    isError,
  } = useGetPackingSlipListQuery(facilityId, { skip: !facilityId });

  const htiColumns = [
    {
      field: "dateOrdered",
      headerName: "Date Ordered",
      flex: 1,
      minWidth: 100,
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      valueGetter: (params) => dayjs(params.value).toDate(),
      valueFormatter: (params) => {
        const isDateValid = dayjs(params.value).isValid();
        return isDateValid
          ? dayjs(params.value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },
    {
      field: "lastUpdatedBy",
      headerName: "Last Updated By",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "dateInvoiced",
      headerName: "Date Invoiced",
      flex: 1,
      minWidth: 100,
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      valueGetter: (params) =>
        !isNull(params.value)
          ? dayjs(params.value).format(SCREEN_CONSTANTS.Date_Format)
          : "--",
    },

    {
      field: "billTypeName",
      headerName: "Bill To",
      flex: 1,
      minWidth: 100,
    },

    {
      field: "total",
      headerName: "Total",
      flex: 1,
      minWidth: 100,
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return <Typography>$ {params.row.total}</Typography>;
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`addHtiOrders?id=${facilityId}&orderId=${params.row.id}`}
              data={htiOrders}
              title="Community HTI Order"
            />
            <DeleteButton
              index={params.row.id}
              entityName={params.row?.lastUpdatedBy}
              apiPath="deletePackingSlip"
            />
            <OptionsButton index={params.row.id} name={params.row.name} />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        marginBottom={"8px"}>
        <BaseButton
          sx={{ marginLeft: "0px !important" }}
          To={`addHtiOrders?id=${facilityId}`}
          endIcon={"playlist_add"}
          text={"Add HTI Order"}
        />
      </Grid>
      <BaseDataGrid
        rows={htiOrders}
        loading={isLoading}
        columns={htiColumns}
        error={isError}
        autoHeight={false}
        height={{ md: "37dvh", xl: "40dvh" }}
      />
    </>
  );
}

function OptionsButton({ index }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [printPackingSlip, { isLoading, isSuccess, data }] =
    usePrintPackingSlipMutation();

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isSuccess && data && !isLoading) {
      DownloadLinkHelper(data);
    }
  }, [isSuccess, data, isLoading]);

  return (
    <>
      <FormControlLabel
        sx={{ marginLeft: "0px" }}
        control={
          <IconButton
            aria-label="Corporate Group Options"
            color="warning"
            onClick={handleClick}
            disableRipple>
            <span className="material-icons">more_vert</span>
          </IconButton>
        }
      />
      <Menu
        id={"edit-menu" + index}
        MenuListProps={{
          "aria-labelledby": "menu-button-" + index,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem disableRipple>
          <Link
            onClick={() => {
              printPackingSlip({ id: index, printPrice: true });
              handleClose();
            }}
            style={{ textDecoration: "none", color: "black" }}>
            Print w/ Pricing
          </Link>
        </MenuItem>
        <MenuItem disableRipple>
          <Link
            onClick={() => {
              printPackingSlip({ id: index, printPrice: false });
              handleClose();
            }}
            style={{ textDecoration: "none", color: "black" }}>
            Print w/o Pricing
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
}
