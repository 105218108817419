import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./ResidentDelete.css";
import {
  useDeleteResidentMutation,
  useGetResidentDetailQuery,
  useGetTablesideHistoryQuery,
  useMoveResidentToReserveMutation,
} from "../../../../store/apis/ResidentApis";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../../store/slices/authSlice/AuthSlice";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import dayjs from "dayjs";

export default function ResidentDelete() {
  const facilityId = useSelector(selectFacilityId);
  const [searchParams] = useSearchParams();
  const [residentId] = useState(searchParams.get("id"));
  const [resident, setResident] = useState({});
  const navigate = useNavigate();

  const { data: residentDetails } = useGetResidentDetailQuery(
    {
      residentId: residentId,
      facilityId: facilityId,
    },
    { skip: !residentId || !facilityId }
  );

  const { data: tablesideHistory } = useGetTablesideHistoryQuery(
    {
      residentId: residentId,
    },
    { skip: !residentId }
  );

  const [
    deleteResident,
    { isSuccess: isDeleteSuccess, isLoading: isDeleteLoading },
  ] = useDeleteResidentMutation();

  const [
    moveToReserved,
    { isSuccess: isMoveToReserveSuccess, isLoading: isMoveLoading },
  ] = useMoveResidentToReserveMutation();

  useEffect(() => {
    if (residentDetails) {
      setResident(residentDetails.resident);
    }
  }, [residentDetails]);

  function DeleteResident() {
    deleteResident(residentId);
  }

  function MoveResidentToReserved() {
    moveToReserved(residentId);
  }

  useEffect(() => {
    if (isMoveToReserveSuccess && !isMoveLoading) {
      navigate("/MealCard");
    }
  }, [isMoveToReserveSuccess, isMoveLoading]);

  useEffect(() => {
    if (isDeleteSuccess && !isDeleteLoading) {
      navigate("/MealCard");
    }
  }, [isDeleteSuccess, isDeleteLoading]);

  return (
    <>
      <Box sx={{ padding: "5px", marginTop: "10px" }}>
        <Box display={"flex"} margin={"5px"} alignItems={"center"}>
          <Typography component={"h3"}>
            Are you sure you want to delete this Resident?
          </Typography>
          <Button
            onClick={() => DeleteResident()}
            color={"error"}
            className="baseButton"
            variant="contained"
            endIcon={<span className="material-icons">delete</span>}>
            Delete
          </Button>
        </Box>
        <Box>
          <Box className="deleteWarningBox">
            <Box className="deleteWarningBoxText">
              {`Warning: There are ${
                tablesideHistory?.length || 0
              } Tableside orders associated with this Resident
            in the last 30 days. If you select 'Delete', this will
            delete ALL TableSide orders for this user`}
            </Box>
            <Button
              onClick={() => MoveResidentToReserved()}
              color={"primary"}
              className="baseButton"
              variant="contained"
              endIcon={<span className="material-icons">assignment</span>}>
              Move to Reserved Instead
            </Button>
          </Box>
          <Box marginTop={"10px"}>
            <Grid container spacing={3}>
              <Grid xs={6} item>
                <Box>
                  <Typography component={"h3"} fontWeight={"700"}>
                    First Name
                  </Typography>{" "}
                  <Typography>{resident.firstName}</Typography>
                </Box>
              </Grid>
              <Grid xs={6} item>
                <Box>
                  <Typography component={"h3"} fontWeight={"700"}>
                    Last Name
                  </Typography>{" "}
                  <Typography>{resident.lastName}</Typography>
                </Box>
              </Grid>
              <Grid xs={6} item>
                <Box>
                  <Typography component={"h3"} fontWeight={"700"}>
                    Title{" "}
                  </Typography>{" "}
                  <Typography>{resident.nameTitle}</Typography>
                </Box>
              </Grid>
              <Grid xs={6} item>
                <Box>
                  <Typography component={"h3"} fontWeight={"700"}>
                    Date of Birth
                  </Typography>{" "}
                  <Typography>
                    {resident.dateOfBirth
                      ? dayjs(resident.dateOfBirth).format(
                          SCREEN_CONSTANTS.Date_Format
                        )
                      : ""}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={6} item>
                <Box>
                  <Typography component={"h3"} fontWeight={"700"}>
                    Physician
                  </Typography>{" "}
                  <Typography>{resident.physician}</Typography>
                </Box>
              </Grid>
              <Grid xs={6} item>
                <Box>
                  <Typography component={"h3"} fontWeight={"700"}>
                    Admission Date
                  </Typography>{" "}
                  <Typography>
                    {resident.adminissionDate
                      ? dayjs(resident.adminissionDate).format(
                          SCREEN_CONSTANTS.Date_Format
                        )
                      : ""}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={6} item>
                <Box>
                  <Typography component={"h3"} fontWeight={"700"}>
                    Building
                  </Typography>{" "}
                  <Typography>{resident.building}</Typography>
                </Box>
              </Grid>
              <Grid xs={6} item>
                <Box>
                  <Typography component={"h3"} fontWeight={"700"}>
                    Wing
                  </Typography>{" "}
                  <Typography>{resident.wing}</Typography>
                </Box>
              </Grid>
              <Grid xs={6} item>
                <Box>
                  <Typography component={"h3"} fontWeight={"700"}>
                    Room
                  </Typography>{" "}
                  <Typography>{resident.room}</Typography>
                </Box>
              </Grid>
              <Grid xs={6} item>
                <Box>
                  <Typography component={"h3"} fontWeight={"700"}>
                    Bed
                  </Typography>{" "}
                  <Typography>{resident.bed}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
