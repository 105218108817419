// React Hook Imports
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useCustomDrop } from "../../../../shared/customeHooks/useCustomDrop";
// MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fade } from "@mui/material";
//  Custom Component Import
import { RecipeCard } from "./RecipeCard";
import { BaseButton } from "../../../../shared/BaseButton";
// Slice Imports
import { setIsChangeTrue } from "store/slices/menuPlannerSlice/LeaveNavigation";
// Lodash Imports
import isEmpty from "lodash/isEmpty";
// import size from "lodash/size";
import { isEqual, isNil } from "lodash";

export const MealViewModal = ({
  open,
  close,
  rows,
  column,
  menuPlannerData,
  setMenuPlannerData,
}) => {
  // states Declaration
  const [holdingRecipe, setHoldingRecipe] = useState([{ recipes: [] }]);
  const dispatch = useDispatch();
  const holdingArea = JSON.parse(localStorage.getItem(`holdingArea`));
  // deep cloning
  const menuPlanner = structuredClone(menuPlannerData);
  const holdingAreaClone = structuredClone(holdingRecipe);
  // function to store data in local storage
  const updateLocalStorage = (recipes) => {
    setHoldingRecipe(recipes);
    localStorage.setItem(`holdingArea`, JSON.stringify(recipes));
  };

  // useEffect Section
  useEffect(() => {
    isNil(holdingArea)
      ? setHoldingRecipe([{ recipes: [] }])
      : setHoldingRecipe(holdingArea);
  }, []);
  // when new data insert in localStorage then this event will be trigger
  useEffect(() => {
    const storageListener = (event) => {
      isEqual(event.key, "holdingArea")
        ? setHoldingRecipe(JSON.parse(event.newValue))
        : null;
    };

    // Add event listener for storage changes
    window.addEventListener("storage", storageListener);
    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("storage", storageListener);
    };
  }, []);
  //  This function would be call when Recipe card drop in meal Area
  const statusChangeThisMeal = (item) => {
    dispatch(setIsChangeTrue(true));
    let duplicatedRecipes =
      menuPlannerData?.resultSet[rows]?.days[column]?.recipes;
    duplicatedRecipes = duplicatedRecipes.some(
      (x) => x.recipeId == item.finalData.recipeId
    );
    if (!duplicatedRecipes) {
      let previousRecipes = holdingRecipe[0]?.recipes?.filter(
        (individualRecipe) =>
          individualRecipe.recipeId !== item?.finalData?.recipeId
      );
      holdingAreaClone[0].recipes = previousRecipes;
      setHoldingRecipe([holdingAreaClone[0]]);
      // updateLocalStorage([holdingAreaClone[0]]);
      let updatedRecipes = structuredClone(
        menuPlannerData?.resultSet[rows]?.days[column]?.recipes
      );
      updatedRecipes.splice(item.index, 0, item?.finalData);
      menuPlanner.resultSet[rows].days[column].recipes = updatedRecipes;
      setMenuPlannerData(menuPlanner);
    }
  };

  //  this function would be call when Recipe card drop in Holding Area
  const statusChange = (item) => {
    dispatch(setIsChangeTrue(true));
    let duplicatedRecipes = holdingRecipe[0]?.recipes?.some(
      (x) => x.recipeId == item.finalData.recipeId
    );
    if (!duplicatedRecipes) {
      let previousRecipes =
        menuPlannerData?.resultSet[rows]?.days[column]?.recipes;
      previousRecipes = previousRecipes?.filter(
        (individualRecipe) =>
          individualRecipe.recipeId !== item.finalData.recipeId
      );
      menuPlanner.resultSet[rows].days[column].recipes = previousRecipes;
      setMenuPlannerData(menuPlanner);
      holdingAreaClone[0]?.recipes?.splice(item.index, 0, item?.finalData);
      updateLocalStorage([holdingAreaClone[0]]);
    }
  };
  // Custom Hooks
  const { isOver: isOverThisMeal, drop: dropThisMeal } = useCustomDrop(
    "card",
    statusChangeThisMeal
  );
  const { isOver: isOverHoldingArea, drop: dropHoldingArea } = useCustomDrop(
    "card",
    statusChange
  );
  const moveCard = useCallback((dragIndex, hoverIndex, item) => {
    if (item?.thisMeal) {
      //this check will be true when someone want to sort this meal Box cards
      const updatedCards = structuredClone(
        menuPlannerData?.resultSet[rows]?.days[column]?.recipes
      );
      const movedItem = updatedCards.splice(dragIndex, 1); // Remove the dragged card from its original position
      updatedCards.splice(hoverIndex, 0, movedItem[0]);
      menuPlanner.resultSet[rows].days[column].recipes = updatedCards;
      setMenuPlannerData(menuPlanner);
    } else {
      //this check will be true when someone want to sort this Holding Area cards
      const moveCard = holdingAreaClone[0].recipes.splice(dragIndex, 1);
      holdingAreaClone[0].recipes.splice(hoverIndex, 0, moveCard[0]);
      updateLocalStorage([holdingAreaClone[0]]);
    }
  });
  // function will delete all the recipe from This meal Area
  const deleteThisMeal = () => {
    dispatch(setIsChangeTrue(true));
    menuPlanner.resultSet[rows].days[column].recipes = [];
    setMenuPlannerData(menuPlanner);
  };
  // Function will call to swap from Meal Area to Holding Area
  const swapMealToHolding = () => {
    let mealData = menuPlannerData?.resultSet[rows]?.days[column]?.recipes;
    holdingAreaClone[0].recipes = mealData;
    updateLocalStorage([holdingAreaClone[0]]);
    setMenuPlannerData(menuPlanner);
  };
  // Function will call to swap from Holding Area to Meal Area
  const swapHoldingToMeal = () => {
    dispatch(setIsChangeTrue(true));
    menuPlanner.resultSet[rows].days[column].recipes =
      holdingAreaClone[0]?.recipes;
    setMenuPlannerData(menuPlanner);
  };
  // Function will call to swap both Meal Area and Holding Area
  const swapBothMealAndHolding = () => {
    dispatch(setIsChangeTrue(true));
    const holdingCopy = holdingAreaClone[0].recipes;
    let mealData = menuPlannerData?.resultSet[rows]?.days[column]?.recipes;
    holdingAreaClone[0].recipes = mealData;
    updateLocalStorage([holdingAreaClone[0]]);
    menuPlanner.resultSet[rows].days[column].recipes = holdingCopy;
    setMenuPlannerData(menuPlanner);
  };
  // Function will call to delete all the recipes from Holding Area
  const deleteHoldingArea = () => {
    holdingAreaClone[0].recipes = [];
    updateLocalStorage([holdingAreaClone[0]]);
    setMenuPlannerData(menuPlanner);
  };
  // Recipe card Rendering
  const renderCard = (data, index, thisMeal, isholdingArea) => {
    return (
      <RecipeCard
        key={index}
        rows={rows}
        column={column}
        isDescription={false}
        isCost={false}
        data={data}
        index={index}
        moveCard={moveCard}
        thisMeal={thisMeal}
        isholdingArea={isholdingArea}
      />
    );
  };
  // UI section
  return (
    <>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ invisible: true }}>
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
              backgroundColor: "var(--whiteColor)",
              boxShadow: "var(--darkBoxShadow)",
              borderRadius: "var(--borderRadius)",
              p: 4,
            }}>
            <Typography
              sx={{ textAlign: "center", fontSize: "16px" }}
              id="modal-modal-title"
              variant="h6"
              component="h2">
              Meal Options
            </Typography>
            <span
              onClick={close}
              style={{
                color: "grey",
                position: "absolute",
                top: "5px",
                right: "5px",
                cursor: "pointer",
              }}
              className="material-icons">
              close
            </span>
            <Box display="flex" flexDirection="row" margin="10px" width="100%">
              <Box display="flex" flexDirection="row" width="100%">
                <Box
                  display="flex"
                  flexDirection="column"
                  width="45%"
                  height="95%"
                  borderRadius="var(--borderRadius)">
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    marginBottom="10px">
                    <Typography
                      sx={{ fontSize: "16px", fontWeight: "bold" }}
                      id="modal-modal-title"
                      variant="h6"
                      component="h6">
                      This Meal
                    </Typography>
                    <span
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={deleteThisMeal}
                      className="material-icons">
                      delete
                    </span>
                  </Box>
                  <Box
                    boxShadow="var(--darkBoxShadow)"
                    border="1px solid #CCCCCC"
                    borderRadius="10px"
                    ref={dropThisMeal}
                    paddingX="2px"
                    paddingY="5px"
                    opacity={isOverThisMeal ? "0.5" : 1}
                    display="flex"
                    flexDirection="column"
                    marginTop="8px"
                    height={{ md: "250px", xl: "400px" }}
                    margin="5px"
                    overflow="scroll"
                    alignItems="left">
                    {menuPlannerData?.resultSet[rows]?.days[
                      column
                    ]?.recipes.map((data, index) =>
                      renderCard(data, index, true, false)
                    )}
                  </Box>
                </Box>
                <Box
                  width="10%"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center">
                  <Box
                    backgroundColor="var(--tealColor)"
                    paddingX="20px"
                    paddingY="5px"
                    borderRadius="var(--borderRadius)"
                    textAlign="center"
                    marginBottom="5px"
                    sx={{ cursor: "pointer" }}
                    onClick={swapMealToHolding}>
                    <span
                      style={{ color: "white", cursor: "pointer" }}
                      className="material-icons">
                      arrow_forward
                    </span>
                  </Box>
                  <Box
                    backgroundColor="var(--tealColor)"
                    paddingX="20px"
                    paddingY="5px"
                    borderRadius="var(--borderRadius)"
                    textAlign="center"
                    marginBottom="5px"
                    sx={{ cursor: "pointer" }}
                    onClick={swapHoldingToMeal}>
                    <span
                      style={{ color: "white", cursor: "pointer" }}
                      className="material-icons">
                      arrow_back
                    </span>
                  </Box>
                  <Box
                    backgroundColor="var(--tealColor)"
                    paddingX="20px"
                    paddingY="5px"
                    borderRadius="var(--borderRadius)"
                    textAlign="center"
                    marginBottom="5px"
                    sx={{ cursor: "pointer" }}
                    onClick={swapBothMealAndHolding}>
                    <span style={{ color: "white" }} className="material-icons">
                      swap_calls
                    </span>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  width="45%"
                  maxHeight="95%"
                  borderRadius="var(--borderRadius)">
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    marginBottom="10px">
                    <Typography
                      sx={{ fontSize: "16px", fontWeight: "bold" }}
                      id="modal-modal-title"
                      variant="h6"
                      component="h6">
                      Holding Area
                    </Typography>
                    <span
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={deleteHoldingArea}
                      className="material-icons">
                      delete
                    </span>
                  </Box>
                  <Box
                    boxShadow="var(--darkBoxShadow)"
                    border="1px solid #CCCCCC"
                    borderRadius="10px"
                    ref={dropHoldingArea}
                    paddingX="2px"
                    paddingY="5px"
                    opacity={isOverHoldingArea ? "0.5" : 1}
                    display="flex"
                    flexDirection="column"
                    marginTop="8px"
                    height={{ md: "250px", xl: "400px" }}
                    margin="5px"
                    overflow="scroll"
                    alignItems="left">
                    {!isEmpty(holdingRecipe)
                      ? holdingRecipe[0]?.recipes?.map((data, index) =>
                          renderCard(data, index, false, true)
                        )
                      : null}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <BaseButton onClick={close} text="Close" />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
