import {
  Card,
  Fade,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import LinearProgress from "@mui/material/LinearProgress";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useGetOrdersNotSubmittedReportMutation } from "../../../../store/apis/ReportAPIs";
import { DownloadLinkHelper } from "../../../shared/utils/helpers";
import "../Reports.css";

export default function BillingReport() {
  const [
    getReport,
    {
      isError: isError,
      isSuccess: isSuccess,
      isLoading: isLoading,
      data: report,
    },
  ] = useGetOrdersNotSubmittedReportMutation();

  useEffect(() => {
    if (isSuccess) {
      DownloadLinkHelper(report?.data);
    }
  }, [report?.data]);

  const handleClick = () => {
    getReport();
  };

  return (
    <Card sx={{ flexGrow: 1, marginY: 2, paddingX: 2 }}>
      <Typography
        sx={{ marginTop: "15px" }}
        color="primary"
        variant="h5"
        className="userFacilityTitle">
        Select Billing Report to View
      </Typography>

      <Fade
        in={isLoading}
        unmountOnExit
        sx={{ marginTop: "10px", marginBottom: "10px" }}>
        <LinearProgress />
      </Fade>

      <nav aria-label="main mailbox folders">
        <List>
          <ListItem
            key={1}
            disablePadding
            component={Link}
            to="ExportTimeMileage">
            <ListItemButton>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Export Time/Mileage to Sage" />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={2}
            disablePadding
            component={Link}
            to="ExportDietitian1099">
            <ListItemButton>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Export Dietitian 1099 Report" />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={3}
            disablePadding
            component={Link}
            to="ExportHTIOrders">
            <ListItemButton>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Export Orders to Sage" />
            </ListItemButton>
          </ListItem>
          <ListItem key={4} disablePadding onClick={() => handleClick()}>
            <ListItemButton>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Orders not yet Submitted for Billing" />
            </ListItemButton>
          </ListItem>
        </List>
      </nav>

      {isError ? (
        <Typography color={"error"}>
          Error occured during report generation. Please try again later.
        </Typography>
      ) : undefined}
    </Card>
  );
}
