import { DMStyles } from "styles/Styles";
import { theme } from "styles/theme";

export const buttonsData = [
  {
    id: 4,
    text: "Visit Summary",
    Link: "/Consultant/MyRosters/VisitDetails/VisitReport",
    visitReportTypeId: "1",
    color: "green",
  },
  {
    id: 0,
    text: "Nutrition Care",
    Link: "/Consultant/MyRosters/VisitDetails/AddNutritionalCare",
    visitReportTypeId: "4",
    color: "orange",
  },
  {
    id: 1,
    text: "Meal Observation",
    Link: "/Consultant/MyRosters/VisitDetails/VisitReport",
    visitReportTypeId: "2",
    color: "purple",
  },
  {
    id: 2,
    text: "Kitchen Observation",
    Link: "/Consultant/MyRosters/VisitDetails/VisitReport",
    visitReportTypeId: "3",
    color: "blue",
  },
  {
    id: 3,
    text: "Dietitian Memo",
    Link: "/Consultant/MyRosters/VisitDetails/addMemo",
    visitReportTypeId: "5",
    color: "maroon",
  },
];

export const Nutrition_ScreenNames = [
  {
    id: 1,
    screenName: false,
    bgColor: theme.palette.primary.main,
    fontWeight: "600",
    color: DMStyles.White,
    marginBottom: "5px",
  },
  {
    id: 2,
    screenName: "Nutrition Care",
    bgColor: theme.palette.orange.main,
    fontWeight: "500",
    color: DMStyles.White,
    marginBottom: "0px",
  },
];
