import React, { useEffect, useState } from "react";
import GeneratedForm from "../../../../../../../shared/generatedForm/GeneratedForm";
import {
  RESIDENT_ASSESSMENT_DEFAULTS,
  RESIDENT_ASSESSMENT_FIELDS,
} from "./forms.constants";
import { useForm } from "react-hook-form";
import {
  useAddNutritionalCareMutation,
  useNutritionalCareByIdQuery,
  useUpdateNutritionalCareMutation,
} from "../../../../../../../../store/apis/NutritionalCareAPIs";
import { Box, Grid } from "@mui/material";
import BaseSubmitButton from "../../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EditSkeleton } from "../../../../../../../admin/admin.overlays";
import { setErrorsForProperties } from "../../../../../../../shared/utils/helpers";
import HeaderBox from "components/consultant/shared/HeaderBox";
import { Nutrition_ScreenNames } from "../../constants";
import { BackButton } from "components/shared/BackButton";
import BaseInput from "components/shared/baseForm/BaseInput";
import { useGetVisitReportsByIdQuery } from "store/apis/ConsultantApis";

export default function AddResidentAssessment() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const assessmentId = searchParams.get("id");
  const visitReportId = searchParams.get("visitReportId");
  const visitPlanId = searchParams.get("visitPlanId");
  const [hiddenList, setHiddenList] = useState([]);
  const [validationSummary, setValidationSummary] = useState();

  const isEdit = !!assessmentId;

  const {
    data: currentAssessment,
    isLoading: currentLoading,
    isSuccess: currentSuccess,
  } = useNutritionalCareByIdQuery(assessmentId, { skip: !assessmentId });

  const isEditLoading = isEdit && currentLoading;

  const [
    updateAssessment,
    { error: addError, isLoading: addLoading, isSuccess: addSuccess },
  ] = useUpdateNutritionalCareMutation();
  const [
    addAssessment,
    { error: updateError, isLoading: updateLoading, isSuccess: updateSuccess },
  ] = useAddNutritionalCareMutation();

  const errorResponse = addError || updateError;
  const isLoading = addLoading || updateLoading;
  const isSuccess = addSuccess || updateSuccess;

  if (isSuccess) navigate(-1);

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: RESIDENT_ASSESSMENT_DEFAULTS,
  });

  const {
    control,
    setError,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = methods;

  const { data: visitReportData } = useGetVisitReportsByIdQuery(visitReportId);

  useEffect(() => {
    if (visitReportData?.description) {
      setValue("description", visitReportData?.description);
    }
  }, [visitReportData]);

  const onSubmit = (data) => {
    if (isEdit) {
      updateAssessment(data);
      return;
    }
    data[`visitReportId`] = visitReportId;
    addAssessment(data);
  };

  useEffect(() => {
    if (!currentLoading && currentSuccess) {
      if (currentAssessment?.subTypes === null) {
        reset({ ...currentAssessment, subTypes: [] });
      } else {
        reset(currentAssessment);
      }
      showInputs(currentAssessment);
    }
  }, [currentLoading, currentSuccess]);

  useEffect(() => {
    if (errorResponse) {
      setErrorsForProperties({
        setError: setError,
        responseError: errorResponse,
        setValidationSummary: setValidationSummary,
      });
    }
  }, [errorResponse]);

  const assessmentTypeId = watch("assessmentTypeId");
  const otherNeeded = watch("otherNeeded");
  const changeToCarePlanNeeded = watch("changeToCarePlanNeeded");
  const changeToTrayCardNeeded = watch("changeToTrayCardNeeded");
  const dietOrderChangeNeeded = watch("dietOrderChangeNeeded");

  function showInputs(data) {
    // Hide SubTypes Field if asessement type is not 6
    if ((data?.assessmentTypeId || assessmentTypeId) !== 6) {
      setHiddenList((prevHiddenList) => [
        ...prevHiddenList,
        { name: "subTypes" },
        { name: "subTypesOffset" },
      ]);
    } else {
      setHiddenList((prevHiddenList) =>
        prevHiddenList.filter(
          (x) => x.name !== "subTypes" && x.name !== "subTypesOffset"
        )
      );
    }

    // Hide OtherComment if Other Needed is not checked
    if (!(data?.otherNeeded || otherNeeded))
      setHiddenList((prevHiddenList) => [
        ...prevHiddenList,
        { name: "otherComment" },
        { name: "otherCommentOffset" },
      ]);
    else
      setHiddenList((prevHiddenList) =>
        prevHiddenList.filter(
          (x) => x.name !== "otherComment" && x.name !== "otherCommentOffset"
        )
      );

    // Hide Change To Care Plan Comment if not checked
    if (!(data?.changeToCarePlanNeeded || changeToCarePlanNeeded))
      setHiddenList((prevHiddenList) => [
        ...prevHiddenList,
        { name: "changeToCarePlanComment" },
        { name: "changeToCarePlanCommentOffset" },
      ]);
    else
      setHiddenList((prevHiddenList) =>
        prevHiddenList.filter(
          (x) =>
            x.name !== "changeToCarePlanComment" &&
            x.name !== "changeToCarePlanCommentOffset"
        )
      );

    // Hide Change to MealCard Comment if not checked
    if (!(data?.changeToTrayCardNeeded || changeToTrayCardNeeded))
      setHiddenList((prevHiddenList) => [
        ...prevHiddenList,
        { name: "changeToTrayCardComment" },
        { name: "changeToTrayCardCommentOffset" },
      ]);
    else
      setHiddenList((prevHiddenList) =>
        prevHiddenList.filter(
          (x) =>
            x.name !== "changeToTrayCardComment" &&
            x.name !== "changeToTrayCardCommentOffset"
        )
      );

    // Hide Diet Order Change Needed Comment if not checked
    if (!(data?.dietOrderChangeNeeded || dietOrderChangeNeeded))
      setHiddenList((prevHiddenList) => [
        ...prevHiddenList,
        { name: "newDiet" },
        { name: "newDietOffset" },
      ]);
    else
      setHiddenList((prevHiddenList) =>
        prevHiddenList.filter(
          (x) => x.name !== "newDiet" && x.name !== "newDietOffset"
        )
      );
  }

  useEffect(() => {
    showInputs();
  }, [
    assessmentTypeId,
    otherNeeded,
    changeToCarePlanNeeded,
    changeToTrayCardNeeded,
    dietOrderChangeNeeded,
  ]);

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton text={"Loading Resident Assessment Information."} />
      ) : (
        <Box padding={"8px"}>
          <BackButton iconDisabled={true} sx={{ marginBottom: "10px" }} />
          <HeaderBox
            visitPlanId={visitPlanId}
            ScreensArray={Nutrition_ScreenNames}
          />
          <Box
            marginY={"15px"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}>
            <Box width={"30%"}>
              <BaseInput
                name="description"
                id="description"
                label="Description"
                size="small"
                control={control}
                errors={errors}
                disabled
              />
            </Box>
            <BaseSubmitButton
              onClick={handleSubmit(onSubmit)}
              isSubmitting={isLoading}
              validationSummary={validationSummary}
            />
          </Box>
          <Grid marginBottom={"8px"} container spacing={2}>
            <GeneratedForm
              control={control}
              list={RESIDENT_ASSESSMENT_FIELDS}
              hiddenList={hiddenList}
              errors={errors}
              queries={[
                {
                  name: "subTypes",
                  query: {
                    assessmentTypeId: assessmentTypeId,
                  },
                  skip: !assessmentTypeId,
                },
              ]}
            />
          </Grid>
        </Box>
      )}
    </>
  );
}
