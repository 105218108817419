import React, { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Tooltip,
  Typography,
  Button,
  //  Tooltip,  Typography,
} from "@mui/material";

import { useSearchParams } from "react-router-dom";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import {
  useDeleteResidentAllergyDislikesMutation,
  useGetResidentAllergyDislikesQuery,
  useGetResidentDietTextQuery,
} from "../../../../store/apis/ResidentAllergyDislikeApis";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import BaseSelect from "components/shared/baseForm/BaseSelect";
import { useForm } from "react-hook-form";
import { useGetSeasonSelectBoxQuery } from "store/apis/SeasonApis";
import { useMenuSelectQuery } from "store/apis/PrintReportApis";
import SubstitutionModal from "./SubstitutionModal";
import AddResidentSubstitutions from "./addResidentSubstitutions/AddResidentSubstitutions";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CopySubstitutionModal from "./CopySubstitutionModal";
import { EditSkeleton } from "components/admin/admin.overlays";

export default function ResidentSubstitutions() {
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get("id");
  const [residentAllergies, setResidentAllergies] = useState();
  const [residentDislikes, setResidentDislikes] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [addSubstitutionModal, setAddSubstitutionModal] = useState(false);
  const [copyModal, setCopyModal] = useState(false);
  const [menuDayId, setMenuDayId] = useState();
  const [menuID, setMenuID] = useState();
  const [seasonId, setSeasonId] = useState(null);
  const [menuId, setMenuId] = useState(null);
  const [allergy, setAllergy] = useState(false);
  const [dislike, setDislike] = useState(false);
  const [data, setData] = useState([]);
  const [allergyAccordion, setAllergyAccordion] = useState(false);
  const [dislikeAccordion, setDislikeAccordion] = useState(false);
  const [menuCategoryName, setMenuCategoryName] = useState("");
  const [menuName, setMenuName] = useState("");
  const [substituteId, setSubstituteId] = useState(null);
  const [specialNote, setSpecialNote] = useState(null);
  const [copyDay, setCopyDay] = useState("");
  //const [dietText, setDietText] = useState();
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  let season_Id = watch("season");
  let menu_Id = watch("menu");

  // Season Selectbos API data
  const {
    data: seasons,
    isLoading: seasonsLoading,
    isError: seasonsError,
  } = useGetSeasonSelectBoxQuery();

  // Menus API base on Seasons

  const {
    data: menus,
    isLoading: menusLoading,
    isError: menusError,
  } = useMenuSelectQuery(
    { seasonId },
    {
      skip: !seasonId,
    }
  );

  // DataGrid API data
  const {
    data: residentSubstitutions,
    isLoading,
    isError,
  } = useGetResidentAllergyDislikesQuery(
    { residentId, menuId },
    { skip: !residentId || !menuId }
  );
  const { data: residentDietText } = useGetResidentDietTextQuery(
    { residentId },
    { skip: !residentId }
  );

  // reset API
  const [resetMenu] = useDeleteResidentAllergyDislikesMutation();

  useEffect(() => {
    if (!isLoading && residentSubstitutions != null) {
      setResidentAllergies(residentSubstitutions?.residentAllergiesDTOList);
      setResidentDislikes(residentSubstitutions?.residentDislikesDTOList);
    }
  }, [residentSubstitutions, isLoading]);

  const seasonSelectBoxId = useCallback(() => {
    if (season_Id) {
      setSeasonId(season_Id);
    }
  }, [season_Id]);

  const menuSelectBoxId = useCallback(() => {
    if (menu_Id) {
      setMenuId(menu_Id);
    }
  }, [menu_Id]);

  useEffect(() => {
    if (season_Id) {
      seasonSelectBoxId();
    }
  }, [seasonSelectBoxId]);

  useEffect(() => {
    if (menu_Id) {
      menuSelectBoxId();
    }
  }, [menuSelectBoxId]);

  useEffect(() => {
    if (residentAllergies?.length > 0) {
      setAllergyAccordion(true);
    }
  }, [residentAllergies]);

  useEffect(() => {
    if (residentDislikes?.length > 0) {
      setDislikeAccordion(true);
    }
  }, [residentDislikes]);

  useEffect(() => {
    setValue("season", 29);
  }, []);

  const allergyColumns = [
    {
      field: "allergyName",
      minWidth: 20,
      flex: 1,
      headerName: "Allergy",
    },
    {
      field: "menuItemName",
      minWidth: 60,
      flex: 2,
      headerName: "Menu Item",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <>
            <Tooltip
              title={
                `${params?.row?.menuItemName} (${params?.row?.menuCategoryName})` ??
                "No Category"
              }>
              <Typography
                sx={{
                  maxWidth: "95%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}>
                {params?.row?.menuItemName ?? ""}
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "menuDayId",
      minWidth: 10,
      flex: 1,
      headerName: "Menu Day",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            <Typography
              sx={{
                cursor: "pointer",
                color: "blue !important",
              }}
              onClick={() => {
                setOpenModal(true);
                setMenuDayId(params?.row?.menuDayId);
                setMenuID(params?.row?.menuId);
              }}>
              {params?.row?.menuDayId}
            </Typography>
          </>
        );
      },
    },
    {
      field: "substitute",
      minWidth: 60,
      flex: 2,
      headerName: "Designated Substitute",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <>
            <Tooltip
              title={
                params?.row?.noSubNeeded
                  ? "No Substitution Needed"
                  : params?.row?.substituteName
                  ? params?.row?.substituteName
                  : "Not Assigned"
              }>
              <Typography
                sx={{
                  maxWidth: "95%", // Adjust this value based on your desired maximum width
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}>
                {params?.row?.noSubNeeded
                  ? "No Substitution Needed"
                  : params?.row?.substituteName
                  ? params?.row?.substituteName
                  : "Not Assigned"}
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "specialNotes",
      minWidth: 50,
      flex: 1.5,
      headerName: "Special Notes",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1.25,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <Button
                sx={{
                  paddingX: "0px !important",
                  marginX: "0px !important",
                }}
                color="warning"
                variant="contained"
                size="small"
                onClick={() => {
                  setData(params.row);
                  setDislike(false);
                  setAllergy(true);
                  setAddSubstitutionModal(true);
                  setMenuName(params?.row?.menuItemName);
                }}>
                <EditIcon />
              </Button>
            </Tooltip>

            <Tooltip title="Reset designated">
              <RestartAltIcon
                sx={{
                  marginX: "10px",
                  cursor: "pointer",
                  color: "primary.main",
                }}
                onClick={() =>
                  resetMenu({
                    id: params.row.id,
                    residentId: params?.row.residentId,
                  })
                }
              />
            </Tooltip>
            {params?.row?.substituteName && (
              <Tooltip title="Copy">
                <ContentCopyRoundedIcon
                  onClick={() => {
                    setCopyModal(true);
                    setMenuCategoryName(params?.row?.menuCategoryName);
                    setCopyDay(params?.row?.menuDayId);
                    setDislike(false);
                    setAllergy(true);
                    setSubstituteId(params?.row?.substituteId);
                    setSpecialNote(params?.row?.specialNotes);
                  }}
                  color="warning"
                  cursor="pointer"
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  const dislikeColumns = [
    {
      field: "foodTypeName",
      minWidth: 20,
      flex: 1,
      headerName: "Dislike",
    },
    {
      field: "menuItemName",
      minWidth: 60,
      flex: 2,
      headerName: "Menu Item",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <>
            <Tooltip
              title={
                `${params?.row?.menuItemName} (${params?.row?.menuCategoryName})` ??
                "No Category"
              }>
              <Typography
                sx={{
                  maxWidth: "95%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}>
                {params?.row?.menuItemName ?? ""}
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "menuDayId",
      minWidth: 10,
      flex: 1,
      headerName: "Menu Day",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            <Typography
              sx={{
                cursor: "pointer",
                color: "blue !important",
              }}
              onClick={() => {
                setOpenModal(true);
                setMenuDayId(params?.row?.menuDayId);
                setMenuID(params?.row?.menuId);
              }}>
              {params?.row?.menuDayId}
            </Typography>
          </>
        );
      },
    },
    {
      field: "substitute",
      minWidth: 60,
      flex: 2,
      headerName: "Designated Substitute",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <>
            <Tooltip
              title={
                params?.row?.noSubNeeded
                  ? "No Substitution Needed"
                  : params?.row?.substituteName
                  ? params?.row?.substituteName
                  : "Not Assigned"
              }>
              <Typography
                sx={{
                  maxWidth: "95%", // Adjust this value based on your desired maximum width
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}>
                {params?.row?.noSubNeeded
                  ? "No Substitution Needed"
                  : params?.row?.substituteName
                  ? params?.row?.substituteName
                  : "Not Assigned"}
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "specialNotes",
      minWidth: 50,
      flex: 1.5,
      headerName: "Special Notes",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1.25,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <Button
                sx={{
                  paddingX: "0px !important",
                  marginX: "0px !important",
                }}
                color="warning"
                variant="contained"
                size="small"
                onClick={() => {
                  setData(params.row);
                  setDislike(true);
                  setAllergy(false);
                  setAddSubstitutionModal(true);
                  setMenuName(params?.row?.menuItemName);
                }}>
                <EditIcon />
              </Button>
            </Tooltip>

            <Tooltip title="Reset designated">
              <RestartAltIcon
                sx={{
                  marginX: "10px",
                  cursor: "pointer",
                  color: "primary.main",
                }}
                onClick={() =>
                  resetMenu({
                    id: params.row.id,
                    residentId: params?.row.residentId,
                  })
                }
              />
            </Tooltip>
            {params?.row?.substituteName && (
              <Tooltip title="Copy">
                <ContentCopyRoundedIcon
                  onClick={() => {
                    setCopyModal(true);
                    setMenuCategoryName(params?.row?.menuCategoryName);
                    setCopyDay(params?.row?.menuDayId);
                    setDislike(true);
                    setAllergy(false);
                    setSubstituteId(params?.row?.substituteId);
                    setSpecialNote(params?.row?.specialNotes);
                  }}
                  color="warning"
                  cursor="pointer"
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      {openModal ? (
        <SubstitutionModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          menuDayId={menuDayId}
          menuId={menuID}
          residentId={residentId}
        />
      ) : null}

      {addSubstitutionModal ? (
        <AddResidentSubstitutions
          open={addSubstitutionModal}
          onClose={() => setAddSubstitutionModal(false)}
          allergy={allergy}
          dislike={dislike}
          menuName={menuName}
          data={data}
        />
      ) : null}

      {copyModal ? (
        <CopySubstitutionModal
          open={copyModal}
          onClose={() => setCopyModal(false)}
          allergy={allergy}
          dislike={dislike}
          menuCategoryName={menuCategoryName}
          copyDay={copyDay}
          data={dislike ? residentDislikes : allergy ? residentAllergies : []}
          residentId={residentId}
          menuId={menuId}
          substituteId={substituteId}
          specialNote={specialNote}
        />
      ) : null}

      <Box sx={{ padding: "5px" }}>
        <Box marginY={1}>
          <Box marginY={1}>
            <Grid container spacing={1}>
              <Grid item sx={12} md={3} lg={3} xl={3}>
                <BaseSelect
                  name="season"
                  id="season"
                  label="Season"
                  control={control}
                  errors={errors || seasonsError}
                  loading={seasonsLoading}
                  options={seasons || []}
                />
              </Grid>
              <Grid item sx={12} md={3} lg={3} xl={3}>
                <BaseSelect
                  name="menu"
                  id="menu"
                  label="Menu"
                  control={control}
                  errors={errors || menusError}
                  loading={menusLoading}
                  options={menus || []}
                />
              </Grid>
              <Grid item sx={12} md={2} lg={2} xl={2}></Grid>
              <Grid
                item
                sx={6}
                md={4}
                lg={4}
                xl={4}
                textAlign="end"
                paddingRight={2}>
                <Typography variant="h6" component="h6" fontSize="15px">
                  Diet:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {residentDietText?.diet ?? "No Diet Assigned"}
                  </span>
                </Typography>
                <Typography variant="h6" component="h6" fontSize="15px">
                  Diet Texture:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {residentDietText?.dietTexture ??
                      "No Diet Texture Assigned"}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box>
            {!isLoading ? (
              <Box
                height={{ sx: "45vh", md: "45vh", lg: "65vh", xl: "74vh" }}
                overflow="scroll">
                <Accordion
                  expanded={allergyAccordion}
                  onChange={() => setAllergyAccordion(!allergyAccordion)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      bgcolor: "primary.main",
                      borderRadius: "10px",
                    }}>
                    <Typography
                      component="h6"
                      variant="h6"
                      fontWeight="bold"
                      fontSize="15px"
                      sx={{
                        color: "white !important",
                      }}>
                      Allergies
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <BaseDataGrid
                      rows={residentAllergies ?? []}
                      columns={allergyColumns}
                      error={isError}
                      loading={isLoading}
                      autoHeight={false}
                      height={{ md: "40vh", lg: "43vh", xl: "43vh" }}
                      sx={{
                        marginY: "0px !important",
                        paddingY: "0px !important",
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={dislikeAccordion}
                  onChange={() => setDislikeAccordion(!dislikeAccordion)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      bgcolor: "primary.main",
                      borderRadius: "10px",
                    }}>
                    <Typography
                      component="h6"
                      variant="h6"
                      fontWeight="bold"
                      fontSize="15px"
                      sx={{
                        color: "white !important",
                      }}>
                      Dislikes
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <BaseDataGrid
                      rows={residentDislikes ?? []}
                      columns={dislikeColumns}
                      error={isError}
                      loading={isLoading}
                      autoHeight={false}
                      height={{ md: "40vh", lg: "43vh", xl: "43vh" }}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
            ) : (
              <EditSkeleton />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
