import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const {
  USERS,
  SYSTEM_MESSAGES,
  USER_FACILITIES,
  FACILITIES,
  USER_ROLES,
  USER_CREDENTIALS,
  ROLES,
} = API_ENDPOINTS;

export const UserAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query({
      query: (userId) => `/${USERS}/${userId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response ? [{ type: USERS, id: response.id }, USERS] : [USERS],
    }),
    getSystemMessagesByUserId: builder.query({
      query: (userId) =>
        `/${SYSTEM_MESSAGES}/GetSystemMessageListForUser?userId=${userId}`,
      transformResponse: (response) => response?.data,

      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: SYSTEM_MESSAGES, id })),
              SYSTEM_MESSAGES,
            ]
          : [SYSTEM_MESSAGES];
      },
    }),
    getUserSelectBox: builder.query({
      query: (query) => `/${USERS}/searchbyname?searchText=${query}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label:
              res.name ||
              `${res?.firstName} ${res?.lastName} (${res?.emailAddress})`,
          };
        }),
    }),
    searchUsersByName: builder.query({
      query: ({ searchTerm, archived, skip, take }) =>
        `/${USERS}/search?searchText=${searchTerm}&showArchived=${archived}&skip=${skip}&take=${take}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [...response.items.map(({ id }) => ({ type: USERS, id })), USERS]
          : [USERS],
    }),
    getClientSuccessUserSelect: builder.query({
      query: ({ roleId }) => `/${USERS}/ByRole?roleId=${roleId}`,
      keepUnusedDataFor: 120,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.fullName,
          };
        }),
    }),
    downloadUsersReport: builder.query({
      query: (reportName) => `/${USERS}/${reportName}`,
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({ url: `/${USERS}/${userId}`, method: "DELETE" }),
      invalidatesTags: (result, error, arg) => [{ type: USERS, id: arg.id }],
    }),
    addUser: builder.mutation({
      query: (data) => ({ url: `/${USERS}`, method: "POST", body: data }),
      invalidatesTags: (result) => [{ type: USERS, id: result.id }],
    }),
    updateUser: builder.mutation({
      query: (data) => ({ url: `/${USERS}`, method: "PUT", body: data }),
      invalidatesTags: (result) => [{ type: USERS, id: result.id }, 
                                    { type: USER_ROLES, id: result.id }],
    }),
    userFacilities: builder.query({
      query: (userId) => `/${USER_FACILITIES}?userId=${userId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              ...response.map(({ id }) => ({ type: USER_FACILITIES, id })),
              USER_FACILITIES,
            ]
          : [USER_FACILITIES],
    }),
    addUserFacility: builder.mutation({
      query: ({ facilityId, userId }) => ({
        url: `/${USER_FACILITIES}?facilityId=${facilityId}&userId=${userId}`,
        method: "POST",
      }),
      invalidatesTags: (result) => [{ type: USER_FACILITIES, id: result.id }],
    }),
    availableUserFacilities: builder.query({
      // eslint-disable-next-line no-unused-vars
      query: ({ searchTerm, skip, take }) =>
        `/${FACILITIES}/search?searchText=${searchTerm || ""}&skip=${
          skip || 0
        }&take=${take || 100}`,
      transformResponse: (response) => {
        return response?.data;
      },
      providesTags: (response) =>
        response
          ? [
              ...response.items.map(({ id }) => ({
                type: USER_FACILITIES,
                id,
              })),
              USER_FACILITIES,
            ]
          : [USER_FACILITIES],
    }),
    deleteUserFacility: builder.mutation({
      query: (id) => ({ url: `/${USER_FACILITIES}/${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, arg) => [
        { type: USER_FACILITIES, id: arg.id },
      ],
    }),
    userRoles: builder.query({
      query: (userId) => `/${USER_ROLES}/User?userId=${userId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              ...response.map(({ id }) => ({ type: USER_ROLES, id })),
              USER_ROLES,
            ]
          : [USER_ROLES],
    }),
    addUserRole: builder.mutation({
      query: ({ roleId, userId }) => ({
        url: `/${USER_ROLES}`,
        method: "POST",
        body: { roleId: roleId, userId: userId },
      }),
      invalidatesTags: (result) => [{ type: USER_ROLES, id: result.id }],
    }),
    availableUserRoles: builder.query({
      query: () => `/${ROLES}`,
      transformResponse: (response, _, arg) => {
        return response?.data.filter((res) => {
          return !arg?.selectedRoles?.some((x) => x.roleId == res.id);
        });
      },
      providesTags: (response) =>
        response
          ? [
              ...response.map(({ id }) => ({ type: USER_ROLES, id })),
              USER_ROLES,
            ]
          : [USER_ROLES],
    }),
    deleteUserRole: builder.mutation({
      query: (id) => ({ url: `/${USER_ROLES}/${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, arg) => [
        { type: USER_ROLES, id: arg.id },
      ],
    }),
    getUserCredentials: builder.query({
      query: (userId) => `/${USERS}/credentials/${userId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: USER_CREDENTIALS, id: response.id }, USER_CREDENTIALS]
          : [USER_CREDENTIALS],
    }),
    generateReportPassword: builder.query({
      query: () => `/${USERS}/generateReportPassword`,
      transformResponse: (response) => response?.data,
    }),
    updateUserCredentials: builder.mutation({
      query: (data) => ({
        url: `/${USERS}/credentials`,
        method: "PUT",
        body: data,
        formData: true,
      }),
      invalidatesTags: (result) => [
        { type: USER_CREDENTIALS, id: result.id },
        { type: USERS, id: result.id },
      ],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetCurrentUserQuery,
  useGetSystemMessagesByUserIdQuery,
  useSearchUsersByNameQuery,
  useDownloadUsersReportQuery,
  useDeleteUserMutation,
  useAddUserMutation,
  useUpdateUserMutation,
  useGetUserSelectBoxQuery,
  useUserFacilitiesQuery,
  useAddUserFacilityMutation,
  useAvailableUserFacilitiesQuery,
  useDeleteUserFacilityMutation,
  useUserRolesQuery,
  useAddUserRoleMutation,
  useAvailableUserRolesQuery,
  useDeleteUserRoleMutation,
  useGetUserCredentialsQuery,
  useLazyGenerateReportPasswordQuery,
  useUpdateUserCredentialsMutation,
  useGetClientSuccessUserSelectQuery,
} = UserAPIs;
