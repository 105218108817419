import React, { useEffect } from "react";
import { useNutritionalCaresQuery } from "../../../../../../../store/apis/NutritionalCareAPIs";
import { useSearchParams } from "react-router-dom";
import BaseDataGrid from "../../../../../../shared/baseDataGrid/BaseDataGrid";
import YesNoDisplay from "../../../../../../shared/YesNoDisplay";
import { Box } from "@mui/material";
import { EditButton } from "../../../../../../shared/EditButton";
import DeleteButton from "../../../../../../shared/DeleteButton";
import { BaseButton } from "../../../../../../shared/BaseButton";
import { SCREEN_CONSTANT } from "../../../constants";
import { Nutrition_ScreenNames } from "../constants";
import HeaderBox from "components/consultant/shared/HeaderBox";
import { BackButton } from "components/shared/BackButton";
import BaseInput from "components/shared/baseForm/BaseInput";
import { useForm } from "react-hook-form";
import { useGetVisitReportsByIdQuery } from "store/apis/ConsultantApis";

export default function NutritionalCares() {
  // states
  const [searchParams] = useSearchParams();
  const visitReportId = searchParams.get("visitReportId");
  const visitPlanId = searchParams.get(SCREEN_CONSTANT.VISIT_PLAN_ID_QUERY);

  //  useform Hook
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  // destructuring
  const {
    control,
    setValue,
    formState: { errors },
  } = methods;
  // api Section
  // api to get reports
  const { data, isLoading, error } = useNutritionalCaresQuery(visitReportId, {
    skip: !visitReportId,
  });
  const { data: visitReportData } = useGetVisitReportsByIdQuery(visitReportId);
  useEffect(() => {
    setValue("description", visitReportData?.description);
  }, [visitReportData]);
  // dataGrid Columns
  const columns = [
    {
      field: "residentName",
      minWidth: 125,
      flex: 1,
      headerName: "Resident",
    },
    {
      field: "assessmentType",
      minWidth: 170,
      flex: 1,
      headerName: "Assessment Type",
    },

    {
      field: "dietOrderChangeNeeded",
      minWidth: 170,
      flex: 1,
      headerName: `Diet or Medical Order`,
      cellClassName: "cellCenterAlign",

      renderCell: (params) => (
        <YesNoDisplay value={params?.row?.dietOrderChangeNeeded} />
      ),
    },
    {
      field: "changeToTrayCardNeeded",
      minWidth: 100,
      flex: 1,
      headerName: `Meal Card`,
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => (
        <YesNoDisplay value={params?.row?.changeToTrayCardNeeded} />
      ),
    },
    {
      field: "changeToCarePlanNeeded",
      minWidth: 100,
      flex: 1,
      headerName: `Care Plan`,
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => (
        <YesNoDisplay value={params?.row?.changeToCarePlanNeeded} />
      ),
    },
    {
      field: "otherNeeded",
      minWidth: 100,
      flex: 1,
      headerName: `Other`,
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => <YesNoDisplay value={params?.row?.otherNeeded} />,
    },
    {
      field: "noChangeNeeded",
      minWidth: 50,
      flex: 1,
      headerName: `No Change Needed`,
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => (
        <YesNoDisplay value={params?.row?.noChangeNeeded} />
      ),
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      maxWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <EditButton
              index={params.row.id}
              To={`/Consultant/MyRosters/VisitDetails/AddResidentAssessment?visitPlanId=${visitPlanId}&id=${params.row.id}&visitReportId=${visitReportId}`}
              // data={MenusData}
              title="Menu"
            />
            <DeleteButton
              index={params.row.id}
              entityName={params.row?.residentName}
              apiPath="deleteNutritionalCare"
            />
          </div>
        );
      },
    },
  ];
  return (
    <Box padding={"8px"}>
      <BackButton iconDisabled={true} sx={{ marginBottom: "10px" }} />
      <HeaderBox
        visitPlanId={visitPlanId}
        ScreensArray={Nutrition_ScreenNames}
      />

      <Box display={"flex"}>
        <Box width={"30%"}>
          <BaseInput
            name="description"
            id="description"
            label="Description"
            size="small"
            control={control}
            errors={errors}
            disabled
          />
        </Box>
        <BaseButton
          colors={"primary"}
          To={`/Consultant/MyRosters/VisitDetails/AddResidentAssessment?visitPlanId=${visitPlanId}&visitReportId=${visitReportId}`}
          endIcon={"add"}
          text={"Add Resident"}
        />
      </Box>

      <Box height={"12px"}></Box>
      <BaseDataGrid
        rows={data}
        loading={isLoading}
        columns={columns}
        error={error}
        autoHeight={true}
      />
    </Box>
  );
}
