import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useGetFacilityMealExtraQuery } from "../../../store/apis/FacilityMealExtraApis";
import { Typography, Tooltip } from "@mui/material";
import { WarningText } from "../../shared/WarningText";
import { useSearchParams } from "react-router-dom";
import BaseDataGrid from "../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "../../shared/EditButton";
import DeleteButton from "../../shared/DeleteButton";
import { selectFacilityId } from "../../../store/slices/authSlice/AuthSlice";
import BaseSearch from "../../shared/baseSearch/BaseSearch";
import { BaseButton } from "components/shared/BaseButton";

export default function FacilityMealExtrasSelection() {
  const currentId = useSelector(selectFacilityId);
  const SEARCH_PARAM = "search";
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const [debouncedValue, setDebouncedValue] = useState("");

  const {
    data: facilityMealExtras,
    isError: facilityMealExtrasError,
    isFetching: facilityMealExtrasLoading,
  } = useGetFacilityMealExtraQuery(
    {
      facilityId: currentId,
      searchText: debouncedValue || "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const facilityMealExtrasColumns = [
    {
      field: "mealExtrasName",
      headerName: "Meal Add-Ons",
      flex: 3,
    },
    {
      field: "facilitySpecific",
      headerName: "House Specific",
      flex: 2,
      minWidth: 10,

      renderCell: (params) => {
        return columnBoolean(params?.row?.facilitySpecific);
      },
    },
    {
      field: "isSupplement",
      minWidth: 10,
      flex: 1,
      headerName: "Supplement?",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return columnBoolean(params?.row?.isSupplement);
      },
    },
    {
      field: "isFood",
      minWidth: 10,
      flex: 1,
      headerName: "Food?",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return columnBoolean(params?.row?.isFood);
      },
    },
    {
      field: "isBeverage",
      minWidth: 10,
      flex: 1,
      headerName: "Beverage?",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return columnBoolean(params?.row?.isBeverage);
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      flex: 0,
      disableClickEventBubbling: true,
      headerName: "Edit",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            {params?.row?.facilitySpecific ? (
              <EditButton
                index={params.row.id}
                To={
                  params.row?.facilitySpecific
                    ? `addMealExtras?facilityId=${currentId}&id=${params.row.mealExtraId}`
                    : `AddMealExtrasSelection?facilityId=${currentId}&id=${params.row.id}`
                }
                data={facilityMealExtras}
                title="Meal Add-Ons"
              />
            ) : null}
          </div>
        );
      },
    },
    {
      field: "delete",
      sortable: false,
      disableColumnMenu: true,
      flex: 0,
      disableClickEventBubbling: true,
      headerName: "Delete",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <DeleteButton
              index={params.row.id}
              entityName={params.row?.mealExtrasName}
              apiPath="deleteFacilityMealExtra"
            />
          </div>
        );
      },
    },
  ];

  function columnBoolean(param) {
    return (
      <>
        <Typography variant="p" width={40}>
          {param ? "Yes" : "No"}
        </Typography>
        {param ? (
          <Tooltip title="Yes">
            <span
              style={{ marginLeft: "8px" }}
              className="material-icons tealColor">
              verified_user
            </span>
          </Tooltip>
        ) : (
          <Tooltip title="No">
            <span
              style={{ marginLeft: "8px" }}
              className="material-icons redColor">
              gpp_bad
            </span>
          </Tooltip>
        )}
      </>
    );
  }
  return (
    <>
      <Typography
        backgroundColor="white"
        variant="h6"
        component="h6"
        margin={"5px"}
        sx={{
          fontWeight: "bold",
        }}>
        Meal Add-Ons
      </Typography>
      <WarningText
        text={`Directions: User can add community specific Meal Add-Ons that might not be found on DiningRD's existing list.
              These new additions can be added to DiningRD's Meal Add-Ons list or user can customize their own Meal Add-Ons list.`}
        width="80%"
        sx={{
          marginLeft: "2px !important",
          marginY: "5px !important",
        }}
      />
      <div className="actionsRow">
        <div className="leftActions">
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedValue}
            setSearchParams={setSearchParams}
          />
          <BaseButton
            text="Add House Meal Add-On"
            To={`addMealExtras?facilityId=${currentId}`}
            endIcon={<span className="material-icons">home</span>}
          />
          <BaseButton
            text="Build Custom List"
            To={`AddMealExtrasSelection?facilityId=${currentId}`}
            endIcon={<span className="material-icons">fastfood</span>}
          />
        </div>
      </div>

      <BaseDataGrid
        rows={facilityMealExtras}
        columns={facilityMealExtrasColumns}
        error={facilityMealExtrasError}
        loading={facilityMealExtrasLoading}
        autoHeight={false}
        height={{
          xs: "50vh",
          sm: "65vh",
          md: "47vh",
          lg: "48vh",
          xl: "51vh",
        }}
        sx={{
          marginY: "0px !important",
          paddingY: "0px !important",
        }}
      />
    </>
  );
}
