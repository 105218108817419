import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuItem,
  Divider,
  Button,
  CircularProgress,
  Tooltip,
  Box,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "./UserSetup.css";
import DeleteModal from "../../../../shared/deleteModal/DeleteModal";
import { DownloadLinkHelper } from "../../../../shared/utils/helpers";

import TempPassword from "./tempPassword/TempPassword";
import {
  useDownloadUsersReportQuery,
  useSearchUsersByNameQuery,
} from "../../../../../store/apis/UserApis";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import YesNoDisplay from "../../../../shared/YesNoDisplay";
import { EditButton } from "../../../../shared/EditButton";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";
import { useSnackbar } from "notistack";
import { b2cPolicies, loginRequest } from "authConfig";
import { useMsal } from "@azure/msal-react";

function UserSetup() {
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });
  const SEARCH_PARAM = "search";
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValueDebounced, setSearchValueDebounced] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );
  const [searchValue, setSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );

  const [showArchived, setShowArchived] = useState(
    searchParams?.get("archived") === "true"
  );
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: userPagedData,
    isFetching: loading,
    isError: hasError,
  } = useSearchUsersByNameQuery({
    searchTerm: searchValueDebounced || "",
    archived: showArchived?.toString() || "false",
    skip: paginationModel.pageSize * paginationModel.page,
    take: paginationModel.pageSize,
  });

  const usersData = userPagedData?.items || [];

  const [reportName, setReportName] = useState(null);

  const {
    data: usersReport,
    isFetching: reportsFetching,
    isSuccess: reportsSuccess,
  } = useDownloadUsersReportQuery(reportName, { skip: reportName === null });
  useEffect(() => {
    setSearchParams({
      search: searchValueDebounced,
      ["archived"]: showArchived,
    });
  }, [showArchived]);

  useEffect(() => {
    if (reportsSuccess) {
      DownloadLinkHelper(usersReport?.data);
      enqueueSnackbar(`${usersReport?.messages[0]}`, {
        variant: "success",
      });
    }
  }, [usersReport?.data]);

  const usersColumns = [
    {
      field: "fullName",
      headerName: "Name",
      renderCell: (params) => {
        return (
          <>
            {params?.row?.fullName}
            {params?.row?.archived && (
              <Tooltip title="Archived">
                <span style={{ marginLeft: "8px" }} className="material-icons">
                  inventory_2
                </span>
              </Tooltip>
            )}
            {params?.row?.administrator && (
              <Tooltip title="Administrator">
                <span
                  style={{ marginLeft: "8px" }}
                  className="material-icons greenColor">
                  admin_panel_settings
                </span>
              </Tooltip>
            )}
          </>
        );
      },
      flex: 1,
      minWidth: 100,
    },
    { field: "facilityName", headerName: "Facility", flex: 1, minWidth: 200 },
    {
      field: "dietitian",
      headerAlign: "center",
      cellClassName: "cellCenterAlign",
      headerName: "Contracted Dietitian",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return <YesNoDisplay value={params?.row?.dietitian} />;
      },
    },
    {
      field: "advancedUser",
      headerAlign: "center",
      cellClassName: "cellCenterAlign",
      headerName: "Advanced",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return <YesNoDisplay value={params?.row?.advancedUser} />;
      },
    },
    {
      field: "lastLogin",
      headerName: "Last Logon",
      flex: 1,
      minWidth: 80,
      valueFormatter: (params) => {
        if (null === params.value) {
          return "";
        } else {
          return new Date(params.value).toLocaleString();
        }
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="actionButtons" style={{ cursor: "pointer" }}>
            <EditButton
              index={params.row.id}
              To={`adduser?id=${params.row.id}`}
              data={usersData}
              title="User"
            />
            <UserOptions
              index={params.row.id}
              id={params.row.id || 0}
              dietitian={params.row["dietitian"]}
              name={params.row["fullName"] || ""}
              username={params.row["username"] || ""}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Box className="actionsRow" flexWrap={{ xs: "wrap", md: "nowrap" }}>
        <Box>
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setSearchValueDebounced}
            setSearchParams={setSearchParams}
            isArchived={true}
            showArchived={showArchived}
            setPaginationModel={setPaginationModel}
          />
        </Box>
        <Box className="leftActions">
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}>
            <Button
              sx={{
                marginLeft: { xs: "0px !important", lg: "5px !important" },
              }}
              component={Link}
              to="adduser"
              color={"primary"}
              className="baseButton"
              variant="contained"
              endIcon={<span className="material-icons">person_add</span>}>
              Add User
            </Button>
            <Button
              size="small"
              className="baseButton"
              sx={{
                marginLeft: { xs: "0px !important", sm: "8px !important" },
              }}
              color={"primary"}
              variant="contained"
              startIcon={
                showArchived ? (
                  <span className="material-icons">check</span>
                ) : (
                  <span className="material-icons">close</span>
                )
              }
              onClick={() => {
                setShowArchived(!showArchived);
              }}>
              Show Archived
            </Button>{" "}
          </Box>

          <ReportButton
            reportsFetching={reportsFetching}
            reportsSuccess={reportsSuccess}
            reportName={reportName}
            usersReport={usersReport}
            setReportName={setReportName}
          />
        </Box>
      </Box>

      <BaseDataGrid
        height={"55dvh"}
        rows={usersData}
        rowCount={userPagedData?.totalCount || 0}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        columns={usersColumns}
        loading={loading}
        error={hasError}
        getRowHeight={() => "auto"}
        sx={{
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "15px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
          },
        }}
      />
    </>
  );
}

const UserOptions = ({ index, id, name, dietitian, username }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { instance } = useMsal();
  const open = Boolean(anchorEl);
  const [openModal, setOpenModal] = useState(false);
  const [openModalPass, setOpenModalPass] = useState(false);
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleLogOnAs(userName) {
    const request = {
      authority: b2cPolicies.authorities.logonAs.authority,
      scopes: loginRequest.scopes,
      extraQueryParameters: {
        targetEmail: userName,
        // eslint-disable-next-line no-undef
        server: process.env.REACT_APP_BASE,
      },
    };

    await instance.loginPopup(request);

    navigate("/home");
  }

  return (
    <>
      <Link
        id={"menu-button-" + index}
        aria-controls={open ? "menu-" + index : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        className="menuButton"
        to={"/"}
        onClick={handleClick}>
        <span className="material-icons">more_vert</span>
      </Link>
      <Menu
        id={"edit-menu" + index}
        MenuListProps={{
          "aria-labelledby": "menu-button-" + index,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem disableRipple>
          <Link
            to={`./userMenus?id=${id}&name=${name}`}
            style={{
              textDecoration: "none",
              color: "black",
              display: "block",
              width: "100%",
            }}>
            Menus User can Access
          </Link>
        </MenuItem>
        <MenuItem disableRipple>
          <Link
            to={`./userRoles?id=${id}`}
            style={{
              textDecoration: "none",
              color: "black",
              display: "block",
              width: "100%",
            }}>
            User Roles
          </Link>
        </MenuItem>
        {!!dietitian && (
          <MenuItem disableRipple>
            <Link
              to={`./DietitianUser?id=${id}`}
              style={{
                textDecoration: "none",
                color: "black",
                display: "block",
                width: "100%",
              }}>
              Dietitian
            </Link>
          </MenuItem>
        )}
        <MenuItem disableRipple>
          <Link
            to={`./userFacilities?id=${id}`}
            style={{
              textDecoration: "none",
              color: "black",
              display: "block",
              width: "100%",
            }}>
            Facilities
          </Link>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenModalPass(true);
            handleClose();
          }}
          disableRipple>
          Reset Password
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem disableRipple onClick={() => handleLogOnAs(username)}>
          Log on as
        </MenuItem>{" "}
        <MenuItem
          onClick={() => {
            setOpenModal(true);
            handleClose();
          }}
          className="menuDeleteButton"
          disableRipple>
          Delete
        </MenuItem>
      </Menu>
      {openModal === true ? (
        <DeleteModal
          id={id}
          entityName={name}
          apiPath={"deleteUser"}
          open={openModal}
          close={() => {
            setOpenModal(false);
          }}
        />
      ) : (
        ""
      )}
      {openModalPass === true ? (
        <TempPassword
          id={id}
          fullname={name}
          username={username}
          open={openModalPass}
          close={() => {
            setOpenModalPass(false);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};

const ReportButton = ({
  reportsFetching,
  reportsSuccess,
  reportName,
  usersReport,
  setReportName,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { enqueueSnackbar } = useSnackbar();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = (name) => {
    handleClose();
    if (!reportsFetching && reportsSuccess && reportName === name) {
      DownloadLinkHelper(usersReport?.data);
      enqueueSnackbar(`${usersReport?.messages[0]}`, {
        variant: "success",
      });
      return;
    }
    setReportName(() => name);
  };

  return (
    <>
      <Button
        id="report-button"
        aria-controls={open ? "report-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        className="baseButton"
        color={"error"}
        disableElevation
        onClick={handleClick}
        startIcon={
          reportsFetching && <CircularProgress color="warning" size={"1rem"} />
        }
        endIcon={<span className="material-icons">arrow_drop_down</span>}>
        Reports
      </Button>
      <Menu
        id="report-menu"
        MenuListProps={{
          "aria-labelledby": "report-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem onClick={() => handleDownload("DietitianList")} disableRipple>
          Download Dietitian Credentials
        </MenuItem>
        <MenuItem onClick={() => handleDownload("UserList")} disableRipple>
          Download List of all user
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserSetup;
