import { DMStyles } from "../../../../styles/Styles";
import { Roles } from "../../../shared/constants";

export const ButtonConstants = [
  {
    id: 5,
    text: "Planned Visit",
    color: DMStyles.White,
    backgroundColor: DMStyles.Gray,
  },
  {
    id: 2,
    text: "Complete, Not Submitted",
    color: DMStyles.White,
    backgroundColor: DMStyles.Teal,
  },
  {
    id: 4,
    text: "Complete, Not Exported",
    color: DMStyles.White,
    backgroundColor: "#FEC92C",
  },
  {
    id: 3,
    text: "Complete, Exported",
    color: DMStyles.White,
    backgroundColor: DMStyles.Green,
  },
  {
    id: 0,
    text: "Visit Past Due",
    color: DMStyles.White,
    backgroundColor: DMStyles.Red,
  },
  {
    id: 1,
    text: "Submit Past Due",
    color: DMStyles.White,
    backgroundColor: "#808080",
  },
];

export const featureData = [
  {
    id: 0,
    name: "Submit Visits",
  },
  {
    id: 1,
    name: "View Schedule",
  },
  {
    id: 2,
    name: "Weekly Schedule",
  },
];
export const optionData = [
  {
    id: 0,
    optionName: "View Community Profile",
    Link: "/Consultant/Community/Profile",
  },
  {
    id: 1,
    optionName: "View Corporate Group",
    Link: "/Consultant/Enterprise/Visit",
  },
  {
    id: 2,
    optionName: "View History",
    Link: "",
  },
];

export const userAllowedWithRoles = [
  Roles.Dietitian_Regional_Admin,
  Roles.Admin,
  Roles.Dietitian_Global_Admin,
];

export const SCREEN_CONSTANT = {
  VISIT_PLAN_ID_QUERY: "visitPlanId",
};

export const KeysButton = [
  {
    id: 0,
    name: "Consult Summary",
    text: "Consult Summary",
    color: DMStyles.White,
    backgroundColor: DMStyles.Green,
  },
  {
    id: 1,
    name: "Meal Service Observation",
    text: "Meal Observation",
    color: DMStyles.White,
    backgroundColor: "#FE782C",
  },
  {
    id: 2,
    name: "Kitchen Sanitation Inspection",
    text: "Kitchen Inspection",
    color: DMStyles.White,
    backgroundColor: "#5B749A",
  },
  {
    id: 3,
    name: "Nutritional Care",
    text: "RD Assessment",
    color: DMStyles.White,
    backgroundColor: DMStyles.PacificBlue,
  },
  {
    id: 4,
    name: "Dietitian Memo",
    text: "Dietitian Memo",
    color: DMStyles.White,
    backgroundColor: DMStyles.Gray,
  },
];

export const typeAbbreviations = {
  1: "Consult Summary",
  2: "Meal Observation",
  3: "Kitchen Inspection",
  4: "RD Assessment",
  5: "Dietitian Memo",
};
