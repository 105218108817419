export const DietitianSetupElements = [
  {
    Name: "Community Dietitian Assignment",
    Link: "/admin/dietitian/communitydietitian",
    IsActive: false,
  },
  {
    Name: "Unassigned Communities",
    Link: "/admin/dietitian/unassignedCommunities",
    IsActive: false,
  },
  {
    Name: "Dietitian Region Management",
    Link: "/admin/dietitian/dietitianregion",
    IsActive: false,
  },
  {
    Name: "Region Management",
    Link: "/admin/dietitian/region",
    IsActive: false,
  },
  {
    Name: "Unlock Finished Reports",
    Link: "/admin/dietitian/unlockfinishedreports",
    IsActive: false,
  },
];
