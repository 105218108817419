import React, { useCallback, useEffect, useMemo, useRef } from "react";
import styles from "./Base.module.css";
import logo from "../../assets/imgs/DiningManagerSmallLogo.jpg";
import { Outlet, useLocation } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SidebarElements } from "./base.constants";
import "@fontsource/material-icons";
import { SidebarLink } from "./SidebarLink";
import {
  Typography,
  Stack,
  Box,
  IconButton,
  List,
  ListItem,
  Toolbar,
  CssBaseline,
  useTheme,
  useMediaQuery,
  Drawer,
} from "@mui/material";
import { useDispatch } from "react-redux";

import { useGetCurrentUserQuery } from "../../store/apis/UserApis";
import useNotifier from "../../utils/useNotifier";
import { ChevronLeft, ChevronRight, Menu } from "@mui/icons-material";
import UserInformation from "./userInformation/UserInformation";
import { BaseApi } from "store/apis/BaseApi";
import { BaseMain } from "./styled/BaseMain";
import { BaseDrawer } from "./styled/BaseDrawer";
import { DrawerHeader } from "./styled/DrawerHeader";
import { BaseAppBar } from "./styled/BaseAppBar";
import { DMStyles } from "styles/Styles";
import useMsalAccount from "utils/useMsalAccount";

function DrawerContent({ sidebar, sidebarSlider, open, setActive, setOpen }) {
  const theme = useTheme();

  useEffect(() => {}, [sidebar]);

  return (
    <>
      <Box flexShrink={0} minHeight={"120px"}>
        <img className={styles.baseLogo} src={logo} alt="DiningRD Logo" />
      </Box>
      {
        // eslint-disable-next-line no-undef
        process.env.REACT_APP_BASE !== "prod" ? (
          <Stack direction="row" justifyContent="center">
            {
              // eslint-disable-next-line no-undef
              process.env.REACT_APP_BASE === "dev" ? (
                <Typography
                  component="span"
                  sx={{
                    bgcolor: theme.palette.warning.main,
                    p: "2px 20px",
                    border: "1px solid grey",
                    borderRadius: "5px",
                  }}>
                  {open ? "Development Site" : "D"}
                </Typography>
              ) : // eslint-disable-next-line no-undef
              process.env.REACT_APP_BASE === "test" ? (
                <Typography
                  component="span"
                  sx={{
                    bgcolor: theme.palette.info.main,
                    p: "2px 20px",
                    border: "1px solid grey",
                    borderRadius: DMStyles.FieldBorderRadius,
                  }}>
                  {open ? "Demo/UAT Site" : "UAT"}
                </Typography>
              ) : undefined
            }
          </Stack>
        ) : undefined
      }
      {sidebarSlider && <div id="sidebarSlider" ref={sidebarSlider}></div>}
      <List
        style={{
          padding: "8px, 0px",
        }}>
        {sidebar.map((data, index) => (
          <ListItem
            className={styles.baseLi}
            key={data.Name + index}
            disablePadding
            sx={{ display: "block" }}>
            <SidebarLink
              open={open}
              Name={data.Name}
              To={data.Link}
              Icon={data.Icon}
              IsActive={data.IsActive}
              SetOpen={setOpen}
              SetActive={setActive}
            />
          </ListItem>
        ))}
      </List>
      <Box
        alignItems={"center"}
        display={"flex"}
        flexDirection={"column"}
        fontSize={"smaller"}
        justifyContent={"center"}
        marginTop={"auto"}
        padding={"10px"}>
        <a
          href="https://healthtechdocs.blob.core.windows.net/general/PrivacyPolicyforHealthTechnologiesv2.pdf"
          target="_blank"
          rel="noreferrer">
          {!open ? "Pri..." : "Privacy Policy"}
        </a>
        <a
          href="https://healthtechdocs.blob.core.windows.net/general/DiningRDTermsofUse2022.pdf"
          target="_blank"
          rel="noreferrer">
          {!open ? "Ter..." : "Terms of Use"}{" "}
        </a>
      </Box>
    </>
  );
}

export default function Base() {
  const theme = useTheme();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = React.useState(isBigScreen);
  const { userId, roles: userRoles } = useMsalAccount();
  const dispatch = useDispatch();

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  const toggleDrawer = useCallback(() => {
    setOpen(!open);
  }, [open]);

  // Refetch all active queries if the user is changed (using logonAs for example)
  useEffect(() => {
    dispatch(BaseApi.util.resetApiState());
  }, [userId]);

  useEffect(() => {}, [instance?.getActiveAccount()]);

  useNotifier();
  const { data: currentUserData } = useGetCurrentUserQuery(userId, {
    skip: !isAuthenticated || userId == null,
    refetchOnMountOrArgChange: true,
  });
  const sidebarSlider = useRef();
  const currentLocation = useLocation();
  const currentPathName = currentLocation.pathname;
  const isMenuPlanner = currentPathName.includes("MenuPlanner");

  let activeFound = false;

  const filteredSidebar = useMemo(() => {
    return isAuthenticated
      ? SidebarElements.filter((x) => {
          if (x.AllowedRoles) {
            if (userRoles) {
              for (const element of x.AllowedRoles) {
                if (userRoles.some((y) => y === element)) {
                  return true;
                }
              }
            }
            return false;
          }
          return true;
        })
          .map((res) => {
            let flag = false;
            if (
              (currentPathName.includes(res.Link) ||
                currentPathName.includes(res.AltLink)) &&
              (res?.InvalidLink
                ? !currentPathName.includes(res.InvalidLink)
                : true)
            ) {
              res.IsActive = true;
              flag = true;
            } else if (currentLocation?.key === "default") {
              SidebarElements[0].IsActive = true;
              flag = true;
            } else {
              res.IsActive = false;
            }
            if (res.Link === "/" || !flag) {
              res.IsActive = false;
            }
            return res;
          })
          .reverse()
          .map((res) => {
            if (res.IsActive) {
              if (!activeFound) {
                activeFound = true;
              } else {
                res.IsActive = false;
              }
            }
            return res;
          })
          .reverse()
      : SidebarElements.filter((x) => !x.AuthenticatedOnly)
          .map((res) => {
            let flag = false;
            if (
              (currentPathName.includes(res.Link) ||
                currentPathName.includes(res.AltLink)) &&
              (res?.InvalidLink
                ? !currentPathName.includes(res.InvalidLink)
                : true)
            ) {
              res.IsActive = true;
              flag = true;
            } else if (currentLocation?.key === "default") {
              SidebarElements[0].IsActive = true;
              flag = true;
            } else {
              res.IsActive = false;
            }
            if (res.Link === "/" || !flag) {
              res.IsActive = false;
            }
            return res;
          })
          .reverse()
          .map((res) => {
            if (res.IsActive) {
              if (!activeFound) {
                activeFound = true;
              } else {
                res.IsActive = false;
              }
            }
            return res;
          })
          .reverse();
  }, [isAuthenticated, userId, currentPathName, userRoles]);

  useEffect(() => {
    if (!filteredSidebar.some((x) => x.IsActive)) {
      SetActive(0, 0, currentPathName);
    }
  }, [filteredSidebar, currentPathName]);

  function SetActive(offsetTop, offsetHeight, name) {
    if (sidebarSlider?.current && !(!isAuthenticated && name == null)) {
      sidebarSlider.current.style.top = offsetTop + "px";
      sidebarSlider.current.style.height = offsetHeight + "px";
    }

    if (!filteredSidebar.some((x) => x.IsActive === true)) {
      if (sidebarSlider?.current) {
        sidebarSlider.current.style.top = 0 + "px";
        sidebarSlider.current.style.height = 0 + "px";
      }
    }
  }

  // Reset activeFound for the next render
  activeFound = false;
  return (
    <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
      <CssBaseline />
      <BaseAppBar id="appHeader" position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={isBigScreen ? handleDrawerOpen : toggleDrawer}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && isBigScreen && { display: "none" }),
            }}>
            <Menu />
          </IconButton>
          {isBigScreen && <UserInformation isBigScreen={isBigScreen} />}
        </Toolbar>
      </BaseAppBar>
      {!isMenuPlanner && (
        <React.Fragment>
          {isBigScreen ? (
            <BaseDrawer
              variant={"permanent"}
              open={open}
              anchor="left"
              ModalProps={{
                keepMounted: true,
              }}
              sx={{ zIndex: 0 }}>
              <DrawerHeader>
                {!open ? (
                  ""
                ) : (
                  <IconButton onClick={handleDrawerClose}>
                    {theme.direction === "rtl" ? (
                      <ChevronRight />
                    ) : (
                      <ChevronLeft />
                    )}
                  </IconButton>
                )}
              </DrawerHeader>

              <DrawerContent
                sidebar={filteredSidebar}
                open={open}
                sidebarSlider={sidebarSlider}
                setActive={SetActive}
              />
            </BaseDrawer>
          ) : (
            <Drawer
              variant={"temporary"}
              open={open}
              anchor="left"
              onClose={handleDrawerClose}
              ModalProps={{
                keepMounted: true,
              }}>
              <DrawerHeader>
                {!open ? (
                  ""
                ) : (
                  <IconButton onClick={handleDrawerClose}>
                    {theme.direction === "rtl" ? (
                      <ChevronRight />
                    ) : (
                      <ChevronLeft />
                    )}
                  </IconButton>
                )}
              </DrawerHeader>
              <UserInformation isBigScreen={isBigScreen} />
              <DrawerContent
                sidebar={filteredSidebar}
                open={open}
                setActive={SetActive}
                setOpen={setOpen}
              />
            </Drawer>
          )}
        </React.Fragment>
      )}
      <BaseMain>
        <DrawerHeader />
        <Outlet context={currentUserData} />
      </BaseMain>
    </Box>
  );
}
