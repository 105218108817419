export const AdminElements = [
  {
    Name: "Groups",
    Icon: "supervised_user_circle",
    IsActive: true,
    Children: [
      {
        Name: "Users",
        Link: "/admin/usermenu",
        IsActive: false,
      },
      {
        Name: "Communities",
        Link: "/admin/communities",
        IsActive: false,
      },
      {
        Name: "Vendors",
        Link: "/admin/vendors",
        IsActive: false,
      },
    ],
  },
  {
    Name: "Setups",
    Icon: "tune",
    IsActive: false,
    Children: [
      {
        Name: "Dietitian",
        Link: "/admin/dietitian",
        IsActive: true,
      },
      {
        Name: "Recipe",
        Link: "/admin/recipes",
        IsActive: false,
      },
      {
        Name: "MealCard",
        Link: "/admin/mealcard",
        IsActive: false,
      },
    ],
  },
  {
    Name: "Reports",
    Icon: "print",
    IsActive: false,
    Children: [
      {
        Name: "Recipe",
        Link: "/admin/recipereport",
        IsActive: false,
      },
      {
        Name: "Vendor",
        Link: "/admin/vendorreport",
        IsActive: false,
      },
      {
        Name: "Billing",
        Link: "/admin/billingreport",
        IsActive: false,
      },
    ],
  },
];
