import React, { useState, useEffect } from "react";
import { Grid, FormHelperText } from "@mui/material";
import BaseSelect from "../../../shared/baseForm/BaseSelect";
import BaseCheckBox from "../../../shared/baseForm/BaseCheckBox";
import BaseInput from "../../../shared/baseForm/BaseInput";
import { DietFields } from "./forms.constants";

export default function DietForm({
  errors,
  control,
  setValue,
  getValues,
  watch,
  diet,
  dietTexture,
  liquidConsistency,
}) {
  const {
    formTextFields,
    formTextFields2,
    formSelectProps,
    formSelectProps2,
    DietCheckBoxes,
  } = DietFields;

  const [dietCheckBoxes, setDietCheckBoxes] = useState([]);

  const isTubeFeed = watch("isTubeFeeding");

  useEffect(() => {
    if (!isTubeFeed) {
      const data = DietCheckBoxes?.filter(
        (item) => item.props?.name !== "isTfandFoodFluids"
      );
      setDietCheckBoxes(data);
    } else {
      setDietCheckBoxes(DietCheckBoxes);
    }
  }, [isTubeFeed]);

  const [menuChecked, setMenuChecked] = useState(
    dietCheckBoxes?.map((item) => {
      return getValues(item.props?.name);
    })
  );

  useEffect(() => {
    if (watch("isNpo") == true) {
      setValue("dietId", null);
      setValue("dietTextureId", null);
      setValue("dietOther", "");
    }
  }, [watch("isNpo")]);

  function handleMenuChange(event, index) {
    const { checked } = event.target;

    if (index === -1) {
      setMenuChecked(
        dietCheckBoxes?.map((item) => {
          setValue(item.props?.name, checked);
          return checked;
        })
      );
      return;
    }

    const newMenuChecked = [...menuChecked];
    newMenuChecked[index] = checked;
    setMenuChecked(newMenuChecked);
    setValue(dietCheckBoxes[index].props?.name, checked);
  }

  return (
    <Grid container spacing={2} margin={2}>
      {formSelectProps.map((data, index) => {
        const props = data?.props;
        return (
          <Grid key={index} item xs={8}>
            <BaseSelect
              {...props}
              options={index == 0 ? diet : dietTexture || []}
              validationProps={data.validationProps}
              errors={errors}
              control={control}
            />
          </Grid>
        );
      })}
      {formTextFields.map((data, index) => {
        const props = data?.props;
        return (
          <Grid key={index} item xs={8}>
            <BaseInput
              {...props}
              control={control}
              name={props?.name}
              validationProps={data?.validationProps}
              errors={errors}
            />
            {errors[props?.id] && (
              <FormHelperText error>{errors[props?.id].message}</FormHelperText>
            )}
          </Grid>
        );
      })}
      {formSelectProps2.map((data, index) => {
        const props = data?.props;
        return (
          <Grid key={index} item xs={8}>
            <BaseSelect
              {...props}
              options={liquidConsistency || []}
              validationProps={data.validationProps}
              errors={errors}
              control={control}
            />
          </Grid>
        );
      })}
      {dietCheckBoxes?.map((data, index) => {
        const props = data?.props;
        return (
          <Grid key={index} item xs={8}>
            <BaseCheckBox
              key={props?.name}
              control={control}
              {...props}
              checked={getValues(props?.name) ? true : false}
              onChange={() => handleMenuChange(event, index)}
              name={props?.name}
              validationProps={data?.validationProps}
              errors={errors}></BaseCheckBox>
          </Grid>
        );
      })}
      {formTextFields2.map((data, index) => {
        const props = data?.props;
        return (
          <Grid key={index} item xs={8}>
            <BaseInput
              {...props}
              control={control}
              name={props?.name}
              validationProps={data?.validationProps}
              errors={errors}
            />
            {/* {errors[props?.id] && (
              <FormHelperText error>{errors[props?.id].message}</FormHelperText>
            )} */}
          </Grid>
        );
      })}
    </Grid>
  );
}
