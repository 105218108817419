import React, { useEffect } from "react";
import GeneratedForm from "../../../../../../shared/generatedForm/GeneratedForm";
import {
  Add_Nutrition_ScreenNames,
  NUTRITIONAL_CARE_DEFAULTS,
  NUTRITIONAL_CARE_FIELDS,
} from "./forms.constants";
import { useForm } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import {
  useAddVisitReportMutation,
  useGetVisitReportsByIdQuery,
  useUpdateVisitReportMutation,
} from "../../../../../../../store/apis/ConsultantApis";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SCREEN_CONSTANT } from "../../../constants";
import HeaderBox from "../../../../../shared/HeaderBox";
import ValidationErrorSummary from "components/shared/validationErrorSummary/ValidationErrorSummary";
import { BackButton } from "components/shared/BackButton";

export default function AddNutritionalCare() {
  // States
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const currentVisitId = searchParams.get(SCREEN_CONSTANT.VISIT_PLAN_ID_QUERY);
  const isEdit = searchParams.get("isEdit");
  const visitReportId = searchParams.get("visitReportId");
  const visitId = searchParams.get("visitId");

  // UseForm For Forms
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: NUTRITIONAL_CARE_DEFAULTS,
  });
  // Destructing useFrom
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const { data: visitReportData } = useGetVisitReportsByIdQuery(visitReportId);

  useEffect(() => {
    if (visitReportData?.description) {
      setValue("description", visitReportData?.description);
    }
  }, [visitReportData]);
  // Mutation Section
  // Mutation to Add Visit Reports
  const [
    addVisitReport,
    { isLoading, isError, isSuccess, data: responseData },
  ] = useAddVisitReportMutation();

  const [
    updateVisitReport,
    {
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      data: responseUpdateData,
    },
  ] = useUpdateVisitReportMutation();
  const visitReportsData = responseData || responseUpdateData;

  const loading = isLoading || updateLoading;
  useEffect(() => {
    isSuccess || updateSuccess
      ? navigate(
          `/Consultant/MyRosters/VisitDetails/NutritionalCares?visitPlanId=${currentVisitId}&visitReportId=${visitReportsData?.id}`
        )
      : null;
    // updateSuccess
    //   ? navigate(
    //       `/Consultant/MyRosters/VisitDetails?visitPlanId=${currentVisitId}`
    //     )
    //   : null;
  }, [isSuccess, updateSuccess]);

  //  function which will be called when some one click on button
  const onSubmit = (data) => {
    // eslint-disable-next-line
    const formData = new FormData();
    formData.append("visitId", visitId);
    formData.append("visitReportTypeId", 4);
    formData.append("description", data.description);
    formData.append("includeSignatureLine", true);
    if (isEdit) {
      formData.append("id", visitReportId);
      updateVisitReport(formData);
    } else {
      addVisitReport(formData);
    }
  };
  // UI Section
  return (
    <Box padding={"12px"}>
      <BackButton iconDisabled={true} sx={{ marginBottom: "10px" }} />
      <HeaderBox
        visitPlanId={currentVisitId}
        ScreensArray={Add_Nutrition_ScreenNames}
      />

      <Grid container spacing={2}>
        <GeneratedForm
          list={NUTRITIONAL_CARE_FIELDS}
          control={control}
          errors={errors}
        />
      </Grid>
      <Box marginTop={"8px"} display={"flex"}>
        <BaseSubmitButton
          text={isEdit ? "Update" : "Save"}
          onClick={handleSubmit(onSubmit)}
          isSubmitting={loading}
        />
        <ValidationErrorSummary errors={errors} />

        {isError == true ? (
          <Typography variant={"p"} color={"error"}>
            An error occurred please check the form again.
          </Typography>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
}
