import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { FACILITIES, DIETITIAN_REPORTS, VISIT_PLANS, VISIT_REPORTS } =
  API_ENDPOINTS;

export const ConsultantAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting Consultants
    getFacilityById: builder.query({
      query: (id) => `/${FACILITIES}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{ type: FACILITIES, id }],
    }),
    getVisitsByFacility: builder.query({
      query: (id) =>
        `/${VISIT_PLANS}/VisitsByFacility?facility=${id}&skip=0&take=1000`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{ type: VISIT_PLANS, id }],
    }),
    getVisitReports: builder.query({
      query: (visitId) => `/${VISIT_REPORTS}?visitId=${visitId}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{ type: VISIT_REPORTS, id }],
    }),
    getVisitReportsById: builder.query({
      query: (id) => `/${VISIT_REPORTS}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{ type: VISIT_REPORTS, id }],
    }),
    getDietitianMemoReportById: builder.query({
      query: (id) => `/${VISIT_REPORTS}/DietitianMemo/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{ type: VISIT_REPORTS, id }],
    }),
    getVisitReportDetailsById: builder.query({
      query: (id) => `/${VISIT_PLANS}/VisitReportDetails/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{ type: VISIT_PLANS, id }],
    }),
    updateDietitianMemoReport: builder.mutation({
      query: (data) => ({
        url: `/${VISIT_REPORTS}/DietitianMemo`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [VISIT_REPORTS],
    }),
    getVisitDetails: builder.query({
      query: (Id) => `/${VISIT_PLANS}/VisitDetails/${Id}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{ type: VISIT_PLANS, id }],
    }),
    getVisitReportsTask: builder.query({
      query: (type) => `/${VISIT_REPORTS}/VisitTasks?type=${type}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{ type: VISIT_REPORTS, id }],
    }),
    visitReports: builder.mutation({
      query: (data) => ({
        url: `/${VISIT_REPORTS}/DietitianMemo`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [VISIT_REPORTS],
    }),

    getVisitsByCorporateGroup: builder.query({
      query: (id) =>
        `/${VISIT_PLANS}/VisitsByCorporateGroup?corporateGroupId=${id}&skip=0&take=1000`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{ type: VISIT_PLANS, id }],
    }),
    getLoggedRecord: builder.query({
      query: (id) => `/${VISIT_PLANS}/GetLoggedRecord/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{ type: VISIT_PLANS, id }],
    }),
    getVisitPlanEditDTO: builder.query({
      query: (id) => `/${VISIT_PLANS}/VisitPlanEditDTO?planId=${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{ type: VISIT_PLANS, id }],
    }),
    getVisiPlansFinishedVisit: builder.query({
      query: (id) => `/${VISIT_PLANS}/FinishedVisits?dietitianId=${id}`,
      transformResponse: (response) => response?.data,
      providesTags: [VISIT_PLANS],
    }),
    visitPlans: builder.mutation({
      query: (data) => ({
        url: `/${VISIT_PLANS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [VISIT_PLANS],
    }),
    createEditVisitPlan: builder.mutation({
      query: (data) => ({
        url: `/${VISIT_PLANS}/CreateEditVisitPlan`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [VISIT_PLANS],
    }),
    updateVisitPlans: builder.mutation({
      query: (data) => ({
        url: `/${VISIT_PLANS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [VISIT_PLANS],
    }),
    submitFinishedVisits: builder.mutation({
      query: (data) => ({
        url: `/${VISIT_PLANS}/SubmitFinishedVisits`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [VISIT_PLANS],
    }),
    addLogRecord: builder.mutation({
      query: (data) => ({
        url: `/${VISIT_PLANS}/AddLogRecord`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [VISIT_PLANS],
    }),
    DietWeeklyReport: builder.mutation({
      query: (data) => ({
        url: `/${DIETITIAN_REPORTS}/WeeklyDietitianReport?dietitianId=${data.dietitianId}&month=${data.month}`,
        method: "POST",
        body: data,
      }),
      // Commented this line below because this shouldnt invalidate consultants
      // invalidatesTags: (result) => [{ type: "Consultant", id: result?.id }],
    }),
    insertNonPlannedVisit: builder.mutation({
      query: (data) => ({
        url: `/${VISIT_PLANS}`,
        method: "POST",
        body: data,
      }),
      // Commented this line below because this shouldnt invalidate consultants
      // invalidatesTags: (result) => [{ type: "Consultant", id: result?.id }],
    }),
    // submitReportToHealthTech: builder.mutation({
    //   query: (id) => ({
    //     url: `/${VISIT_PLANS}/SubmitReportToHealthTech?visitId=${id}`,
    //     method: "POST",
    //   }),
    //   transformResponse: (response) => response?.data,
    //   invalidatesTags: [VISIT_PLANS],
    // }),

    addVisitReport: builder.mutation({
      query: (data) => ({
        url: `/${VISIT_REPORTS}`,
        method: "POST",
        body: data,
        formData: true,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: [VISIT_REPORTS],
    }),
    updateVisitReport: builder.mutation({
      query: (data) => ({
        url: `/${VISIT_REPORTS}`,
        method: "PUT",
        body: data,
        formData: true,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: [VISIT_REPORTS],
    }),

    transformResponse: (response) => response?.data,
    invalidatesTags: (result) => [{ type: VISIT_REPORTS, id: result?.id }],
    updateFacilities: builder.mutation({
      query: (data) => {
        const visitId = data.visitId ? `&visitId=${data.visitId}` : "";
        return {
          url: `/${VISIT_PLANS}/UpdateFacility?facilityId=${data.facilityId}&planId=${data.planId}${visitId}`,
          method: "PUT",
          body: data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: (result) => [{ type: VISIT_PLANS, id: result?.id }],
    }),
    deleteVisitReport: builder.mutation({
      query: (id) => ({
        url: `/${VISIT_REPORTS}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: [VISIT_REPORTS],
    }),
    deleteVisitPlane: builder.mutation({
      query: (id) => ({
        url: `/${VISIT_PLANS}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: VISIT_PLANS, id: arg.id },
      ],
    }),
    deleteVisitHours: builder.mutation({
      query: (id) => ({
        url: `/${VISIT_PLANS}/DeleteVisitHours/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: VISIT_PLANS, id: arg.id },
      ],
    }),

    GenerateVisitReport: builder.mutation({
      query: (data) => ({
        url: `/${VISIT_REPORTS}/GenerateReport?id=${data.id}`,
        method: "POST",
        body: data,
      }),
    }),
    GenerateVisitReports: builder.mutation({
      query: (data) => ({
        url: `/${VISIT_REPORTS}/GenerateReports?id=${data.id}`,
        method: "POST",
        body: data,
      }),
    }),
    CreatePortalDocumentByVisit: builder.mutation({
      query: (data) => ({
        url: `/${DIETITIAN_REPORTS}/CreatePortalDocumentsByVisit?visitId=${data.visitId}`,
        method: "POST",
        body: data,
      }),
    }),
    CreatePortalDocumentByFacility: builder.mutation({
      query: (data) => ({
        url: `/${DIETITIAN_REPORTS}/CreatePortalDocumentsByFacility?facilityId=${data.facilityId}&startDate=${data.startDate}&endDate=${data.endDate}`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetFacilityByIdQuery,
  useGetVisitsByFacilityQuery,
  useGetVisitReportsTaskQuery,
  useGetVisitsByCorporateGroupQuery,
  useGetLoggedRecordQuery,
  useGetVisitPlanEditDTOQuery,
  useGetVisitReportsByIdQuery,
  useGetDietitianMemoReportByIdQuery,
  useGetVisitReportDetailsByIdQuery,
  useUpdateDietitianMemoReportMutation,
  useGetVisitDetailsQuery,
  useGetVisitReportsQuery,
  useVisitReportsMutation,
  useDietWeeklyReportMutation,
  useInsertNonPlannedVisitMutation,
  useVisitPlansMutation,
  useUpdateVisitPlansMutation,
  useSubmitFinishedVisitsMutation,
  useGetVisiPlansFinishedVisitQuery,
  useAddLogRecordMutation,
  useAddVisitReportMutation,
  useUpdateVisitReportMutation,
  useCreateEditVisitPlanMutation,
  useGenerateVisitReportMutation,
  useDeleteVisitReportMutation,
  useGenerateVisitReportsMutation,
  useDeleteVisitPlaneMutation,
  useDeleteVisitHoursMutation,
  useUpdateFacilitiesMutation,
  useCreatePortalDocumentByVisitMutation,
  useCreatePortalDocumentByFacilityMutation,

  // useSubmitReportToHealthTechMutation,
} = ConsultantAPIs;
