import React, { useEffect, useRef, useState, useCallback } from "react";
import { Box, Grid, Typography } from "@mui/material";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import {
  defaultRecipeFields,
  recipeCheckboxes,
  recipeFields,
  recipeFieldsCol2,
} from "./forms.constants";
import { useForm } from "react-hook-form";
import {
  useNavigate,
  useSearchParams,
  unstable_useBlocker as useBlocker,
  useLocation,
} from "react-router-dom";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { BaseButton } from "components/shared/BaseButton";
import { WarningText } from "components/shared/WarningText";
import {
  useAddRecipeMutation,
  useGetRecipeByIdUserFacilityQuery,
  useUpdateRecipeMutation,
} from "store/apis/RecipeAPIs";
import { useGetRecipeCategorySelectBoxQuery } from "store/apis/RecipeCategoriesApis";
import { useSelector } from "react-redux";
import { Roles } from "components/shared/constants";
import { selectFacilityId } from "store/slices/authSlice/AuthSlice";
import BaseInput from "components/shared/baseForm/BaseInput";
import BaseSelect from "components/shared/baseForm/BaseSelect";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { EditSkeleton } from "components/admin/admin.overlays";
import "./AddRecipe.css";
import BaseContent from "components/shared/baseContent/BaseContent";
import DeleteButton from "components/shared/DeleteButton";
import BlockerModal from "components/shared/blockerModal/BlockerModal";
import useMsalAccount from "utils/useMsalAccount";

export default function AddRecipe() {
  const currentLocation = useLocation();

  const previousUrl = currentLocation?.state?.previousLink;
  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get("recipeId");
  const Message = searchParams.get("Message");
  const fileInputRef = useRef(null);
  const [file, setFile] = useState();
  const [preview, setPreview] = useState(null);
  const [blockerModal, setBlockerModal] = useState(false);
  const [categoryDisabled, setCategoryDisabled] = useState(false);
  const [hasChange, setHasChange] = useState(false);

  // data from store
  const facilityId = useSelector(selectFacilityId);
  const { userId, roles: userRoles } = useMsalAccount();

  const navigate = useNavigate();

  const isEdit = !!recipeId;

  // useForm hook here
  const {
    control,
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: defaultRecipeFields,
  });

  useEffect(() => {
    // if (Message == "CopyRecipe") setHasChange(isDirty);
    setHasChange(isDirty);
  }, [isDirty]);

  const isBaseRecipe = watch("baseRecipe");

  // Role for Deleting a Recipe

  const canDeleteRecipe = (isHTIRecipe) => {
    const userAllowedWithRoles = [Roles.Admin, Roles.Menu_Team];
    const isRoleAllowedToDelete = userRoles.some((roleToCheck) =>
      userAllowedWithRoles.includes(roleToCheck)
    );

    const canDelete =
      (isRoleAllowedToDelete && isHTIRecipe) ||
      (!isRoleAllowedToDelete && !isHTIRecipe);

    return canDelete;
  };

  // for disabling the category field for non-admin users (and setting to house recipes)
  const isAdmin = () => {
    const userAllowedWithRoles = [Roles.Admin, Roles.Menu_Team];
    const isInRole = userRoles.some((roleToCheck) =>
      userAllowedWithRoles.includes(roleToCheck)
    );
    return isInRole;
  };

  // APIs Calls here

  const {
    data: recipeCategoryList,
    isLoading: recipeCategoryLoading,
    isSuccess: recipeCategorySuccess,
  } = useGetRecipeCategorySelectBoxQuery(
    {
      facilityId: facilityId,
      filterToHTI: false,
    },
    {
      skip: !facilityId,
    }
  );

  const {
    data: currentRecipe,
    isLoading: currentRecipeLoading,
    isSuccess: currentRecipeSuccess,
  } = useGetRecipeByIdUserFacilityQuery(
    {
      recipeId: recipeId,
      userId: userId,
      facilityId: facilityId,
    },
    { skip: !recipeId || !userId || !facilityId }
  );

  const [
    addRecipe,
    {
      data: newRecipe,
      isLoading: addRecipeLoading,
      isSuccess: addRecipeSuccess,
    },
  ] = useAddRecipeMutation();

  const [
    updateRecipe,
    { isLoading: updateRecipeLoading, isSuccess: updateRecipeSuccess },
  ] = useUpdateRecipeMutation();

  const isSubmitting = addRecipeLoading || updateRecipeLoading;
  const isSuccess = addRecipeSuccess || updateRecipeSuccess;

  const handleImageUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (isSuccess)
      navigate(
        `/plateFul/Recipes/Ingredients?recipeId=${
          recipeId ? recipeId : newRecipe?.data?.id
        }`,
        { state: { canEdit: true } }
      );
    if (currentRecipeSuccess) {
      reset(currentRecipe);
      setPreview(currentRecipe?.imageUrl);
    }
  }, [isSuccess, currentRecipeSuccess]);

  useEffect(() => {
    if (recipeCategorySuccess && !recipeCategoryLoading) {
      if (!isAdmin()) {
        // set to house recipe category
        setValue("categoryId", 29);
        setCategoryDisabled(true);
      }
    }
  }, [recipeCategoryLoading, recipeCategorySuccess]);

  // UnSaved Changes Feature here
  // Block navigating elsewhere when data has been entered into the input
  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) =>
      hasChange && currentLocation.pathname !== nextLocation.pathname,
    [hasChange]
  );

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === "blocked") {
      setBlockerModal(true);
    }
  }, [blocker]);

  // Page Reload and navigation alert implementations
  useEffect(() => {
    window.onbeforeunload = function () {
      if (hasChange) {
        return "You have unsaved changes. Are you sure you want to leave?";
      }
    };
  }, [hasChange]);

  const handleOnLeave = () => {
    blocker.proceed();
    setBlockerModal(false);
    setHasChange(false);
  };
  const handleOnStay = () => {
    blocker.reset();
    setBlockerModal(false);
  };
  // onSubmit functionality here
  const onSubmit = (data) => {
    setHasChange(false);
    data.textureId = data.textureId || "";
    data.panSizeId = data.panSizeId || "";
    data.menuCategoryId = data.menuCategoryId || "";

    const formData = new FormData();
    if (file) formData.append("image", file);

    for (let property in data) {
      formData.append(property, data[property]);
    }

    if (!isEdit) {
      addRecipe({ userId, facilityId, data: formData });
    } else if (isEdit) {
      formData.append("removeImage", false);
      updateRecipe({
        userId: userId,
        facilityId: facilityId,
        data: formData,
      });
    }
  };

  return currentRecipeLoading ? (
    <EditSkeleton />
  ) : (
    <>
      {blockerModal ? (
        <BlockerModal
          text={`Hold up! You've got unsaved changes. Are you sure you want to leave?`}
          open={blockerModal}
          onStay={handleOnStay}
          onLeave={handleOnLeave}
        />
      ) : null}

      <BaseContent
        headerText={
          currentRecipe?.name ? `Recipe - ${currentRecipe?.name}` : ""
        }
        backText="Back to Recipe"
        backLink={previousUrl ? previousUrl : -1}
        disableHeight={true}>
        {Message == "CopyRecipe" && (
          <>
            <WarningText
              text={`You are now working with the copied version of the recipe. Change the Name of the recipe to make it unique.`}
              width="97%"
              color="primary"
              sx={{
                marginY: "13px !important",
              }}
            />
            <WarningText
              text={`Don't forget to update the Dislikes for this copied menu.`}
              width="97%"
              color="warning"
              sx={{
                marginY: "13px !important",
              }}
            />
          </>
        )}

        <Box height={{ md: "78vh", xl: "82vh" }}>
          <Box
            display="flex"
            marginLeft="10px"
            marginTop={1}
            gap={2}
            overflow="auto"
            height={{ md: "60vh", lg: "70vh", xl: "73vh" }}>
            <Box width="51.5%" marginBottom={1} mt={1}>
              {isEdit ? (
                <Box>
                  <Typography variant="h6" fontWeight="bold">
                    {currentRecipe?.name}
                  </Typography>
                  <Typography variant="p">
                    Recipe Number : {currentRecipe?.id}
                  </Typography>
                </Box>
              ) : null}
              <Grid
                container
                spacing={1}
                sx={{
                  bgcolor: "#ECECEC",
                  borderRadius: 1,
                  paddingX: "2px",
                  paddingY: "5px",
                  marginY: "10px",
                  marginLeft: "1px",
                }}>
                <GeneratedForm
                  list={recipeCheckboxes}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid container spacing={1}>
                <Grid item lg={12}>
                  <BaseInput
                    name="name"
                    id="name"
                    label="Name"
                    validationProps={{
                      required: REQUIRED_ERROR("name"),
                      maxLength: {
                        value: 50,
                        message: "Max Character Length is 50",
                      },
                    }}
                    control={control}
                    errors={errors}
                  />
                </Grid>

                <Grid item lg={4}>
                  <BaseSelect
                    name="categoryId"
                    id="categoryId"
                    label="Recipe Category"
                    options={recipeCategoryList || []}
                    validationProps={{
                      required: REQUIRED_ERROR("Recipe Category"),
                    }}
                    loading={recipeCategoryLoading}
                    control={control}
                    errors={errors}
                    disabled={categoryDisabled}
                  />
                </Grid>
                <GeneratedForm
                  list={recipeFields}
                  control={control}
                  errors={errors}
                />
              </Grid>
            </Box>
            <Box width="47.5%" marginBottom={1} mt={1}>
              <Grid container spacing={1}>
                <GeneratedForm
                  list={recipeFieldsCol2}
                  control={control}
                  errors={errors}
                />
              </Grid>

              <Typography fontWeight="bold" marginY={0.5}>
                Recipe Photo
              </Typography>
              <Box
                sx={{
                  height: "24vh",
                  border: "1px solid",
                  borderRadius: 2,
                  borderColor: "#00BCBE",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                {!preview ? (
                  <Box>
                    <AddAPhotoIcon
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={handleImageUpload}
                    />
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept=".jpg, .jpeg, .png, .webp, .gif, .tif, .tiff, .bmp"
                      onChange={handleFileChange}
                      hidden
                    />
                    <Typography
                      onClick={handleImageUpload}
                      sx={{
                        fontWeight: "bold",
                        fontSize: 18,
                        mt: 1,
                        cursor: "pointer",
                      }}
                      color="primary">
                      Upload recipe photo
                    </Typography>
                  </Box>
                ) : null}
                {preview && (
                  <img
                    src={preview}
                    alt="Image preview"
                    style={{ width: "50%", height: "100%" }}
                  />
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "0.5rem",
                }}>
                {preview && (
                  <div>
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept=".jpg, .jpeg, .png, .webp, .gif, .tif, .tiff, .bmp"
                      onChange={handleFileChange}
                      hidden
                    />
                    <Typography
                      sx={{
                        color: "#2A537E",
                        fontWeight: "600",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={handleImageUpload}>
                      Upload new recipe photo
                    </Typography>
                  </div>
                )}
              </Box>
              {preview && !isBaseRecipe ? (
                <Typography
                  color="primary"
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    textDecoration: "underline",
                    mt: 0.5,
                    cursor: "pointer",
                  }}
                  onClick={() => setPreview("")}>
                  Upload new recipe photo
                </Typography>
              ) : null}
            </Box>
          </Box>
          <Box
            mt={3}
            pb={1}
            sx={{
              display: "flex",
              alignItems: "center",
            }}>
            {isEdit ? (
              canDeleteRecipe(currentRecipe?.htirecipe) ? (
                <Box width="42%">
                  <DeleteButton
                    index={currentRecipe?.id}
                    entityName={currentRecipe?.name}
                    text="Archive Recipe"
                    variant="contained"
                    apiPath="deletePlatefulRecipes"
                    To={"/plateFul"}
                  />
                </Box>
              ) : null
            ) : null}
            <Box>
              <BaseButton
                fullWidth
                text="Cancel"
                onClick={() => navigate(-1)}
                colors="white"
              />
              <BaseButton
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
                text={isEdit ? "Save" : "Create"}
              />
            </Box>
          </Box>
        </Box>
      </BaseContent>
    </>
  );
}
