import { Grid, Typography, Box } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useResidentAllergySelectQuery } from "../../../../../store/apis/GenericApis";
import BaseSelect from "../../../../shared/baseForm/BaseSelect";
import BaseInput from "../../../../shared/baseForm/BaseInput";
import BaseSubmitButton from "../../../../shared/baseSubmitButton/BaseSubmitButton";
import {
  useAddResidentAllergyMutation,
  useGetResidentAllergyQuery,
  useUpdateResidentAllergyMutation,
} from "../../../../../store/apis/ResidentApis";
import { WarningText } from "../../../../shared/WarningText";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { BackButton } from "components/shared/BackButton";
import BaseBlockerModal from "components/shared/blockerModal/BaseBlockerModal";

export default function AddResidentAllergy() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isDirty, setIsDirty] = useState(false);

  const currentAllergy = location?.state?.data;
  // const ResidentName = location?.state?.name;

  const id = location?.state?.data?.id;
  const residentId = searchParams.get("id");

  const isEdit = !!id;

  const { data: FoodAllergies, isLoading: allergyLoading } =
    useResidentAllergySelectQuery();

  const { data: Allergies, isLoading: isAllergyLoading } =
    useGetResidentAllergyQuery(residentId, { skip: !residentId || isEdit });

  const allergiesData = useMemo(() => {
    return (
      FoodAllergies?.filter(
        (item) => !Allergies?.some((x) => x.allergyFoodId == item.id)
      ) || []
    );
  }, [Allergies, FoodAllergies]);

  const [
    addResidentAllergy,
    { isLoading: addResidentAllergySubmitting, isSuccess: addSuccess },
  ] = useAddResidentAllergyMutation();

  const [
    updateResidentAllergy,
    { isLoading: updateResidentAllergySubmitting, isSuccess: updateSuccess },
  ] = useUpdateResidentAllergyMutation();

  const isSubmitting =
    addResidentAllergySubmitting || updateResidentAllergySubmitting;
  const isSuccess = addSuccess || updateSuccess;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty: valuesChange },
  } = methods;

  useEffect(() => {
    isEdit ? reset(currentAllergy) : null;
  }, [isEdit]);

  function onSubmit(data) {
    setIsDirty(false);
    if (data["allergyFoodId"] !== -1) {
      data["description"] = "";
    }

    if (isEdit) {
      updateResidentAllergy({
        ...data,
        id: id,
        residentId: residentId,
      });
    } else {
      addResidentAllergy({ ...data, residentId: residentId });
    }
  }
  isSuccess && !isSubmitting ? navigate(-1) : null;
  useEffect(() => {
    setIsDirty(valuesChange);
  }, [valuesChange]);
  return (
    <>
      <BaseBlockerModal isDirty={isDirty} />
      <Box sx={{ padding: "5px", marginTop: "10px" }}>
        <BackButton To={-1} />
        <Typography
          marginTop={"10px"}
          variant="p"
          component={"p"}
          fontWeight={600}>
          {"Resident Allergy"}
        </Typography>
        <WarningText
          text={`Warning: You MUST check the specific ingredient label for the food items
            used in your facility for any allergens your specific residents may need
            to avoid. ALWAYS check the ingredients/food items that you purchase as
            manufacturers periodically change the ingredients used in an item. `}
          color="primary.main"
          width="90%"
          sx={{ marginLeft: "0px !important" }}
        />

        <Grid marginY={"8px"} container spacing={2}>
          <Grid item md={4}>
            <BaseSelect
              name="allergyFoodId"
              id="allergyFoodId"
              label="Allergy"
              control={control}
              errors={errors}
              options={allergiesData}
              loading={allergyLoading || isAllergyLoading}
              validationProps={{
                required: REQUIRED_ERROR("Allergy Food"),
              }}
            />
          </Grid>
          <Grid item md={8} />
          <Grid item md={4}>
            {watch("allergyFoodId") === -1 && (
              <>
                <BaseInput
                  name="description"
                  id="description"
                  label="Description"
                  control={control}
                  validationProps={{
                    required:
                      "Description field is required when selecting Other",
                  }}
                  errors={errors}
                />
                <WarningText
                  text={`WARNING: This allergy is not currently attached to any ingredients in our system.
                Please note that items containing this ingredient will NOT be crossed out on the Meal Card
                 for residents who may be allergic to this item. Please contact DiningRd for more information.`}
                  color="warning"
                  width="90%"
                  sx={{ marginLeft: "0px !important" }}
                />
              </>
            )}
          </Grid>
          <Grid item md={8} />

          <Grid item md={4}>
            <BaseSubmitButton
              onClick={handleSubmit(onSubmit)}
              isSubmitting={isSubmitting}
              text={isEdit ? "Submit" : "Create"}
            />
          </Grid>
        </Grid>
        {/* </Box> */}
      </Box>
    </>
  );
}
