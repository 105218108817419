import { REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../../../../shared/generatedForm/GeneratedForm";
import { foodTypeNameValidation } from "../../../../../../utils/ValidationRegex";

export const FOOD_TYPE_FIELDS = [
  {
    props: {
      name: "name",
      id: "name",
      label: "Name",
    },
    validationProps: {
      required: REQUIRED_ERROR("Name"),
      pattern: {
        value: foodTypeNameValidation,
        message: "Name is not valid",
      },
      maxLength: {
        value: 30,
        message: "please enter 30 characters or less",
      },
    },
    size: 6,
  },
  {
    props: {
      label: "Is Snack/Supplement",
      name: "isSnack",
      id: "isSnack",
    },
    size: 6,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      label: "Needs to be Thickened ( § )",
      name: "needsToBeThickened",
      id: "needsToBeThickened",
    },
    size: 6,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      label: "Is Dislike",
      name: "isDislike",
      id: "isDislike",
    },
    size: 6,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      label: "For Breakfast",
      name: "availableBreakfast",
      id: "availableBreakfast",
    },
    size: 6,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      label: "For Lunch",
      name: "availableLunch",
      id: "availableLunch",
    },
    size: 6,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      label: "For Supper",
      name: "availableSupper",
      id: "availableSupper",
    },
    size: 6,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      name: "defaultUnitId",
      id: "defaultUnitId",
      label: "Default Unit",
    },
    size: 6,
    type: FORM_TYPES.SelectAPI,
    endpoint: "getSelectUnits",
    validationProps: {
      required: REQUIRED_ERROR("Default Unit"),
    },
  },
];
