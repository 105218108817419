import { Typography, Grid, CircularProgress, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
// import { useGetOrderTransmissionsListQuery } from "../../../../store/apis/MenuApis";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import {
  useGetDietlistPrintQuery,
  useReviewOrderMutation,
  useChangeQuantityOrderMutation,
  useTransmitOrderMutation,
} from "../../../../../store/apis/MenuApis";
import BaseInput from "../../../../shared/baseForm/BaseInput";
import BaseSubmitButton from "../../../../shared/baseSubmitButton/BaseSubmitButton";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../../../store/slices/authSlice/AuthSlice";
import { useForm } from "react-hook-form";
import BaseContent from "../../../../shared/baseContent/BaseContent";
// import { GridEditInputCell } from "@mui/x-data-grid";
import { DistributorsList } from "components/shared/distributors.constants";
import {
  endingDayValidation,
  startingDayValidation,
} from "utils/commonValidationObjects";
import { zeroToFiveDigitPositiveNumber } from "utils/ValidationRegex";

export default function NewOrderTransmission() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const id = searchParams.get("menuId");
  const facilityId = useSelector(selectFacilityId);

  const location = useLocation();
  const title = location?.state?.title;

  const [rowModesModel, setRowModesModel] = useState({});
  const [rowModesQuantityModel, setRowModesQuantityModel] = useState({});
  const [DataArray, setDataArray] = useState([]);
  const [quantityDataArray, setQuantityDataArray] = useState([]);
  const [isReviewOrderShow, setIsReviewOrderShow] = useState(false);
  const [isChangeQuantityclicked, setIsChangeQuantityclicked] = useState(false);

  const {
    data: diets,
    isFetching: isFetchingDiets,
    isError: hasError,
  } = useGetDietlistPrintQuery(
    {
      menuId: id,
      facilityId,
    },
    {
      skip: !id || !facilityId,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    reviewOrder,
    {
      data: ItemsData,
      isError: addError,
      isLoading: addLoading,
      isSuccess: reviewSuccess,
    },
  ] = useReviewOrderMutation();

  const [
    changeQuantityOrder,
    {
      data: ItemsQuantityData,
      isError: updateError,
      isLoading: updateLoading,
      isSuccess: quantitySuccess,
    },
  ] = useChangeQuantityOrderMutation();

  const [
    transmitOrder,
    {
      data: transmitDataResults,
      isError: transmitError,
      error: transmitErrorMessage,
      isLoading: transmitLoading,
      isSuccess: transmitSuccess,
    },
  ] = useTransmitOrderMutation();

  useEffect(() => {
    setDataArray(diets);
    if (quantitySuccess) {
      setQuantityDataArray(ItemsQuantityData?.data?.itemsToTransmit);
    } else if (reviewSuccess) {
      setQuantityDataArray(ItemsData?.data?.orderTransmissionItems);
    } else {
      setQuantityDataArray([]);
    }
  }, [diets, quantitySuccess, reviewSuccess]);

  useEffect(() => {
    setQuantityDataArray(
      isChangeQuantityclicked
        ? ItemsQuantityData?.data?.itemsToTransmit
        : ItemsData?.data?.orderTransmissionItems
    );
  }, [ItemsData, ItemsQuantityData, isChangeQuantityclicked]);

  useEffect(() => {}, [transmitSuccess]);

  const isError = addError && updateError && transmitError;

  const isSubmitting = addLoading && updateLoading && transmitLoading;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    handleSubmit,
    control,
    trigger,
    watch,
    formState: { errors, dirtyFields },
  } = methods;

  const startDay = watch("startDay");
  const endDay = watch("endDay");

  useEffect(() => {
    if (dirtyFields.startDay && dirtyFields.endDay) trigger("endDay");
  }, [startDay]);

  useEffect(() => {
    if (dirtyFields.endDay && dirtyFields.startDay) trigger("startDay");
  }, [endDay]);

  const onChangeQuantitySubmit = (data) => {
    setIsChangeQuantityclicked(true);
    changeQuantityOrder({
      startDay: parseInt(data?.startDay),
      endDay: parseInt(data?.endDay),
      qty1: parseInt(data?.qty1),
      qty2: parseInt(data?.qty2),
      qty3: parseInt(data?.qty3),
      menuId: id,
      menuDietList: DataArray,
      orderTransmissionId: ItemsData?.data.id,
      itemsToTransmit: quantityDataArray,
    });
  };

  // useEffect(() => {
  //   if (quantitySuccess) {
  //     setIsReviewOrderShow(!isReviewOrderShow);
  //     setIsChangeQuantityclicked(true);
  //   }
  // }, [quantitySuccess]);

  const onSubmit = (data) => {
    reviewOrder({
      startDay: parseInt(data?.startDay),
      endDay: parseInt(data?.endDay),
      qty1: parseInt(data?.qty1),
      qty2: parseInt(data?.qty2),
      qty3: parseInt(data?.qty3),
      menuId: id,
      menuDietList: DataArray,
    });

    if (!isError) setIsReviewOrderShow(!isReviewOrderShow);
  };

  const onSubmitTransmit = (data) => {
    transmitOrder({
      ...data,
      menuId: id,
      menuDietList: DataArray,
      itemsToTransmit: quantityDataArray,
      orderTransmissionId: isChangeQuantityclicked
        ? ItemsQuantityData?.data?.orderTransmissionId
        : ItemsData?.data?.id,
      distributorId: isChangeQuantityclicked
        ? ItemsQuantityData?.data?.distributorId
        : ItemsData?.data?.distributorId,
    })
      .unwrap()
      .then((payload) => {
        const GFS_ID = DistributorsList.find(
          (dist) => dist.label === "Gordon Food Service"
        )?.id;
        //only create new window from returned payload data when distributor is GFS
        if (
          !!GFS_ID &&
          (ItemsQuantityData?.data?.distributorId === GFS_ID ||
            ItemsData?.data?.distributorId === GFS_ID) &&
          payload?.success &&
          payload?.data?.length
        ) {
          window.open(payload.data, "_blank");
        }
      });
    if (!isError) navigate(-1);
  };

  const dietsColumns = [
    {
      field: "dietName",
      headerName: "Diet",
      flex: 4,
    },
    {
      field: "census",
      editable: true,
      headerName: "Census",
      type: "number",
      cellClassName: "bulkWeightInput",
      flex: 1,
      minWidth: 50,
    },
  ];

  // function AmountEditCell(params) {
  //   return (
  //     <GridEditInputCell
  //       tabIndex={params.tabIndex}
  //       {...params}
  //       disabled={params?.row?.vendorItemNumber === null}
  //     />
  //   );
  // }

  const newOrderColumns = [
    {
      field: "ingredientName",
      flex: 1,
      headerName: "Ingredient",
    },
    {
      field: "vendorItemNumber",
      flex: 1,
      headerName: "Vendor Item #	",
    },
    {
      field: "needQuantityDescription",
      flex: 1,
      headerName: "Qty Needed",
    },
    {
      field: "amount",
      flex: 1,
      preProcessEditCellProps: (params) => {
        return {
          ...params,
          value: params.value,
          disabled: params?.row?.vendorItemNumber === null,
        };
      },
      renderHeader: () => (
        <strong style={{ display: "flex", alignItems: "center" }}>
          {"Cases to Order"}
          <span className="material-icons">edit</span>
        </strong>
      ),
      type: "number",
      // renderEditCell: AmountEditCell,
      editable: true,
    },
    {
      field: "packSize",
      flex: 1,
      headerName: "Case Size",
    },
  ];

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow };
    setDataArray(
      DataArray.map((row) => (row.dietId === newRow.dietId ? updatedRow : row))
    );
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowModesQuantityModelChange = (newRowModesModel) => {
    setRowModesQuantityModel(newRowModesModel);
  };

  const processRowQuantityUpdate = (newRow) => {
    const updatedRow = { ...newRow };

    setQuantityDataArray(
      quantityDataArray.map((row) =>
        row.ingredientId === newRow.ingredientId ? updatedRow : row
      )
    );
    return updatedRow;
  };

  return (
    <BaseContent disableHeight={true}>
      <Box height={{ md: "80vh", xl: "85vh" }}>
        <Grid container>
          <Typography variant="h6">
            New Order Transmission for {title} menu
          </Typography>
          <Grid item xs={12} className="print-item">
            <Box display={"flex"} justifyContent={"flex-end"}>
              {!isReviewOrderShow ? (
                <>
                  <BaseSubmitButton
                    size="small"
                    onClick={handleSubmit(onSubmit)}
                    isSubmitting={isSubmitting}
                    endIcon={<span className="material-icons">wysiwyg</span>}
                    text={"Review Order"}
                    style={{ marginBottom: 10 }}
                  />
                </>
              ) : (
                <>
                  <BaseSubmitButton
                    onClick={handleSubmit(onChangeQuantitySubmit)}
                    isSubmitting={isSubmitting}
                    style={{ marginBottom: 10, marginRight: 10 }}
                    endIcon={
                      <span className="material-icons">
                        production_quantity_limits
                      </span>
                    }
                    text={"Change Quantities"}
                  />
                  <BaseSubmitButton
                    onClick={handleSubmit(onSubmitTransmit)}
                    isSubmitting={isSubmitting}
                    style={{ marginBottom: 10 }}
                    endIcon={<span className="material-icons">logout</span>}
                    text={"Transmit Order"}
                    startIcon={
                      transmitLoading && (
                        <CircularProgress color="warning" size={"1rem"} />
                      )
                    }
                  />
                </>
              )}
            </Box>
          </Grid>
          {transmitError && (
            <Typography color={"var(--redColor)"}>
              {transmitErrorMessage.data}
            </Typography>
          )}
          {transmitSuccess && (
            <Typography variant="h6" color={"var(--greenColor)"}>
              {transmitDataResults.data}
            </Typography>
          )}
          {!isReviewOrderShow ? (
            <>
              <Grid item xs={4} className="print-item">
                <BaseInput
                  size="small"
                  label={"Starting Day"}
                  name={"startDay"}
                  id={"startDay"}
                  type={"number"}
                  defaultValue={0}
                  min={0}
                  max={999}
                  validationProps={startingDayValidation({ endName: "endDay" })}
                  placeholder="Starting Day"
                  control={control}
                  errors={errors}
                />
                <BaseInput
                  size="small"
                  label={"Ending Day"}
                  name={"endDay"}
                  type={"number"}
                  id={"endDay"}
                  defaultValue={0}
                  validationProps={endingDayValidation({
                    startName: "startDay",
                  })}
                  placeholder="Ending Day"
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={7} />
              <div className="actionsRow">
                <Typography variant="h5" className="print-heading">
                  Census
                </Typography>
                <Typography variant="p" className="print-heading">
                  Breakfast
                </Typography>
                <BaseInput
                  size="small"
                  label={"Breakfast"}
                  name={"qty1"}
                  id={"qty1"}
                  type={"number"}
                  placeholder=""
                  defaultValue={0}
                  validationProps={{
                    pattern: {
                      value: zeroToFiveDigitPositiveNumber,
                      message: "Please enter positive number less than 100000",
                    },
                  }}
                  control={control}
                  errors={errors}
                />
                <Typography variant="p" className="print-heading">
                  Lunch
                </Typography>
                <BaseInput
                  size="small"
                  label={"Lunch"}
                  name={"qty2"}
                  id={"qty2"}
                  type={"number"}
                  placeholder=""
                  defaultValue={0}
                  min={0}
                  validationProps={{
                    pattern: {
                      value: zeroToFiveDigitPositiveNumber,
                      message: "Please enter positive number less than 100000",
                    },
                  }}
                  control={control}
                  errors={errors}
                />
                <Typography variant="p" className="print-heading">
                  Supper
                </Typography>
                <BaseInput
                  size="small"
                  label={"Supper"}
                  name={"qty3"}
                  id={"qty3"}
                  type={"number"}
                  placeholder=""
                  defaultValue={0}
                  min={0}
                  validationProps={{
                    pattern: {
                      value: zeroToFiveDigitPositiveNumber,
                      message: "Please enter positive number less than 100000",
                    },
                  }}
                  control={control}
                  errors={errors}
                />
              </div>
              <BaseDataGrid
                rows={DataArray}
                loading={isFetchingDiets}
                columns={dietsColumns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                processRowUpdate={processRowUpdate}
                isCellEditable={(params) =>
                  params?.row?.vendorItemNumber !== null
                }
                error={hasError}
                getRowId={(row) => row.dietId}
                autoHeight={false}
                height={{ md: "40vh", xl: "55vh" }}
              />
            </>
          ) : (
            <>
              <Typography variant="p" className="print-heading print-title-box">
                Any exceptions in red below will not be transmitted. These will
                need to be ordered manually.
              </Typography>
              <BaseDataGrid
                rows={
                  quantityDataArray
                  // isChangeQuantityclicked
                  //   ? ItemsQuantityData?.data?.itemsToTransmit
                  //   : ItemsData?.data?.orderTransmissionItems
                }
                loading={addLoading}
                columns={newOrderColumns}
                error={hasError}
                defaultPageSize={100}
                getRowId={(row) => row.ingredientId}
                rowModesModel={rowModesQuantityModel}
                onRowModesModelChange={handleRowModesQuantityModelChange}
                processRowUpdate={processRowQuantityUpdate}
                getRowClassName={(params) =>
                  params.row.vendorItemNumber == null && `emptyVendorItemStyle`
                }
                autoHeight={false}
                height={{ md: "50vh", xl: "62vh" }}
              />
            </>
          )}
        </Grid>
      </Box>
    </BaseContent>
  );
}
